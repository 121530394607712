import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function TDSPolicy() {
  return (
    <>
        <Navbar/>
        <div className='legal'>
        <h1><span>TDS </span> Policy</h1>
<p>In line with the provisions contained in Finance Act, 2023, following TDS policy is applicable for
<br/>all players on Rummydunia from 1 Apr, 2023.

</p>
<br/>
<ul>
    <li>TDS is applicable at the time of withdrawal or deposit refund</li>
    <li>30% TDS is applicable on any positive net winnings at the time of withdrawal</li>

    <li>TDS is also applicable at the end of financial year on year end wallet balance by
considering it as a withdrawal amount. Post applying TDS on the remaining balance (as
per policy), the remaining amount will be carried forward to the next financial year as
deposit balance. Carried forward balance will be considered as investment for next year
and TDS would not be applicable for that amount.
</li>
    <li>Here, Net winnings = Total Withdrawals - Total Deposits (in a financial year). Apr 1 to
Mar 31 duration is considered a financial year.
</li>
<br/>


</ul>
<br/>

<h3>How was TDS deducted before Apr 1, 2023?</h3>
<br/>
<ul>
    <li>Before Apr 1 2023, TDS was deducted at a game level when the winnings were greater
than 10K.</li>
    <li>30% TDS is applicable on any positive net winnings at the time of withdrawal</li>

    <li>Example: Previously if I won Rs 11000 in a game, then 30% TDS will be deducted i.e.
3300 and hence Rs 7700 will be credited in the withdrawal wallet.
</li>
    
<br/>


</ul>
<br/>

<h3>How is the current TDS policy different from the last TDS policy of FY
2022- 2023?</h3>
<br/>
<ul>
    <li>Post Apr 1, if I win some amount let's assume Rs 11000, NO TDS will be deducted while
crediting that amount in the withdrawal wallet. Entire Rs 11000 will be transferred to the
winnings wallet.</li>
    <li>Only when the user will try to withdraw any amount from the wallet, we will look at the
net winnings and basis that calculate the TDS applicable.
</li>

    <li>Hence we will deduct TDS only when the withdrawal running total is more than the
Deposits. If the user is in loss, there will be no TDS. Hence this will be beneficial to the
users.

</li>
    



</ul>
<br/>
<h4>
Please note that the TDS Policy is based on the understanding of the provisions as introduced/
amended by Finance Act, 2023 and the Company reserves its right to modify/ change/ amend
the TDS Policy basis law applicable at the relevant time. Attached is the finance bill amendment
which says that the policy will be applicable from Apr 1, 2023 rather than July 1, 2023 (Refer to
Page 10, Row 31 and 32).

</h4>
<br/>
<h3>
What Does It Mean For Players?How was TDS deducted before Apr 1, 2023?</h3>
<br/>
<h4>
Find below a few scenarios to understand the application of the aforementioned TDS Policy

</h4>
<br/>
<h4>
Scenario 1: If your net winnings (including the withdrawal amount) is more than 0, then 30%
TDS will be deducted on the net winnings at the time of withdrawal.


</h4>
<br/>
<h3>
Example:

</h3>
<br/>
<br/>

<div className='reff-table'>
<div className='reff-table-div'>
<div className='reff-table-div-left'>
<p>Total withdrawals in the financial year(A)</p>
<p>Total deposits in the financial year(B)</p>




</div>
<div className='reff-table-div-right'>
<p>₹5,000</p>
<p>₹10,000</p>



</div>

</div>

</div>
<br/>
<br/>

<h4>
Scenario 1: If your net winnings (including the withdrawal amount) is more than 0, then 30%
TDS will be deducted on the net winnings at the time of withdrawal.


</h4>
<br/>
<br/>

<div className='reff-table'>
<div className='reff-table-div'>
<div className='reff-table-div-left'>
<p>Amount being withdrawn by the player(C)</p>
<p>Net winnings(A+C - B)</p>
<p>30% TDS Applicable on Net Winnings(D)</p>
<p>Amount to be credited in the bank account(C-D) </p>





</div>
<div className='reff-table-div-right'>
<p>₹7,000</p>
<p>₹2,000</p>
<p>₹600</p>
<p>₹6400</p>




</div>

</div>

</div>
<br/>
<br/>

<h4>
Zero TDS Withdrawal amount: If you withdraw upto ₹5,000 , then there will be zero TDS
deducted as net winnings will be ₹0 (₹5,000 + ₹5,000 - ₹10,000). See calculation below:
</h4>
<br/>
<br/>

<div className='reff-table'>
<div className='reff-table-div'>
<div className='reff-table-div-left'>
<p>Total withdrawals in the financial year(A)</p>
<p>Total deposits in the financial year(B)</p>
<p>Amount being withdrawn by the player(C)</p>
<p>Net winnings(A+C - B) </p>





</div>
<div className='reff-table-div-right'>
<p>₹5,000</p>
<p>₹10,000</p>
<p>₹5,000</p>
<p>₹0</p>




</div>

</div>

</div>
<br/>
<br/>

<h4>
<b>Scenario 2:</b> If you have paid TDS in previous withdrawals, then no TDS is applicable on
withdrawals till your net winnings cross the amount for which you have already paid TDS.

</h4>
<br/>
<h3>Example:</h3>
<br/>
<br/>

<div className='reff-table'>
<div className='reff-table-div'>
<div className='reff-table-div-left'>
<p>Total withdrawals in the financial year</p>
<p>Total deposits in the financial year</p>
<p>TDS paid in the financial year
(TDS paid on winnings of ₹6,000 as initially you had positive net
winnings of ₹6,000)</p>






</div>
<div className='reff-table-div-right'>
<p>₹20,000</p>
<p>₹20,000</p>
<p>₹1,800
</p>





</div>

</div>

</div>
<br/>

<br/>
<h4>
In this scenario, No TDS applicable till your net winnings crosses ₹6,000 (winnings already
taxed):

</h4>
<br/>
<ul>
    <li>Zero TDS Withdrawal amount: If you withdraw up to ₹6,000, no TDS is applicable</li>
    <li>If you withdraw ₹10, 000, then 30% TDS is applicable only on ₹4,000 winnings i.e.
₹1,200.</li>

</ul>
<br/>
<h4>
Scenario 3: You have paid TDS on initial winnings during the financial year but your net
winnings have reduced post that.


</h4>
<br/>

<h3>Example:</h3>
<br/>
<br/>

<div className='reff-table'>
<div className='reff-table-div'>
<div className='reff-table-div-left'>
<p>Total withdrawals in the financial year(A)</p>
<p>Total deposits in the financial year(B)</p>
<p>Net winnings in the financial year(A-B)</p>
<p>TDS paid till date</p>







</div>
<div className='reff-table-div-right'>
<p>₹40,000
</p>
<p>₹20,000</p>
<p>₹20,000</p>

<p>₹6,000</p>





</div>

</div>

</div>
<br/>
<br/>
<h4>
Post this, if your net winnings reduces to ₹10,000, then, subject to applicable law, the excess
TDS deducted can be claimed as refund in your annual income tax filing.


</h4>
<br/>
<h4>
Scenario 4: At the end of the financial year, if you have any amount remaining in your wallet
(including table balances), TDS will be applied to such wallet balances if such balances
contribute to any net winnings or profits for the financial year. The remaining amount will be
carried forward to the next year as an opening balance. A withdrawal from the opening balance
will not be subjected to TDS in the next year.

</h4>
<br/>
<br/>

<div className='reff-table'>
<div className='reff-table-div'>
<div className='reff-table-div-left'>
<p>Total withdrawals in the financial year(A) </p>
<p>Total deposits in the financial year(B)</p>
<p>TDS paid till date(C)</p>
<p>Account balance at the end of Mar 31, 2024(D)</p>







</div>
<div className='reff-table-div-right'>
<p>₹40,000
</p>
<p>₹40,000</p>
<p>₹1,500</p>

<p>₹30,000</p>





</div>

</div>

</div>
<br/>

<br/>
<h4>
In above scenario, following calculation is done:
</h4>
<br/>
<br/>

<div className='reff-table'>
<div className='reff-table-div'>
<div className='reff-table-div-left'>
<p>Total withdrawals in the financial year(A+D)</p>
<p>Total deposits in the financial year(B)</p>
<p>Net winnings(A+D-B)</p>
<p>30% TDS Applicable on Net Winnings(E) </p>
<p>TDS paid till date(C)</p>
<p>TDS Remaining to be paid(E-C) </p>








</div>
<div className='reff-table-div-right'>
<p>₹70,000
</p>
<p>₹40,000</p>
<p>₹30,000</p>

<p>₹9,000</p>
<p>₹1,500</p>
<p>₹7,500</p>






</div>

</div>

</div>
<br/>
<br/>

<h4>
Thus, ₹7,500 TDS is deducted from your account balance at the end of Mar 31, 2024 and
remaining balance of ₹22,500 will be carried forward to the next financial year as deposit
amount. You will not have to pay TDS in the next financial year on withdrawal out of this
amount.

</h4>
<br/>
<h4>
Please Note: Information provided on this page is for educational and informational purposes
only and does not constitute tax advisory or recommendation. Players are requested to contact
their financial and tax advisors for specific queries about their tax liability and tax related
matters
</h4>
<br/>

        </div>
        <Footer/>
    </>
  )
}

export default TDSPolicy