import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function PrivacyPolicy() {
  return (
   <>
    <Navbar/>
    <div className=' playrummy'>
        <h3>Privacy  <span>Policy</span></h3>
        <br/>
        <br/>
        <p>Gaddam Studio Private Limited (“Gaddam Studio”, or “we” or “our” or “us”) operates the Rummy
Dunia platform (“Platform”), which enables users in select States of India (“Users”, or “you” and
its grammatical variations) to access and participate in a wide variety of online game of Rummy
in various formats and variations.
This policy (“Privacy Policy”) forms an integral part of the Terms of Service between Gaddam
Studio and Users, and governs how Gaddam Studio collects, uses, shares, and protects the
personal information disclosed by users in the course of their usage of the Platform.
Accordingly, you are advised to read this Privacy Policy carefully before accessing the Platform
</p>
       
    <br/>
        </div>
        <div className='conditions'>
        <br/>
        <br/>
        <br/>
        <h1>Types of information collected</h1>
        <br/>
        <p>We may collect the following types of information</p>
        <br/>
       

        <ul>
            <li>
            Information you provide: when you register on the Platform, you may be required to
provide us with your name, email address, mobile number, display picture, social media
account ID (where submitted by you), and other relevant profile information. Additionally,
you will be required to provide your bank account details when you make use of the add
cash or cash withdrawal options for cash Games, and your Permanent Account Number
(PAN) when you use the cash withdrawal option. In some cases, such as referral
programs, you may provide us with personal information of another person.
  
            </li>
            <li>
            Transaction details initiated by you: When you initiate a cash transaction in relation to
your Account, we receive and store details pertaining to such transaction, including the
payment method used, the amount added to the wallet, and the date and time of the
transaction.

            </li>  
              <li>
              Interaction information: When you interact with the Gaddam Studio’s customer support
telephonically, such telephonic interactions may be recorded and stored.
  
            </li>   
             <li>
             Technical information about you: – We automatically collect certain information from you
when you use the Platform, including your IP address, location, other unique device
identifiers, your browser type, language, the URL of the website which you may have
used to reach our Platform, and cookies (defined below).
  
            </li>   
             <li>
             Usage information: When you play any game offered by us on the Platform, including
any formats of such games, such as tournaments, promotional games, practice games,
and cash games (together, “Games”) on our Platform, we collect details related to the
manner of your use of and interaction with our Platform and other Users such as the
date and time during which you accessed the Platform, location from where you access
the Platform, the amount of money spent on each Game you participate in, the duration
for which you play any Game, and the formats of Game you participate in.


  
            </li>    <li>
            Messages. When you use our Platform to communicate, we receive and collect the
messages you may send or receive on the Platform, and any other content you may
upload or share on the Platform.

  
            </li>    <li>
            Cookies: We use cookies, small text files that uniquely identify your browser and enable
Us to recognize the information you have consented to give us, to store certain types of
information each time you visit any page on our Platform. These cookies do not contain
any personal information and you may choose what cookies you allow by managing your
cookies settings on your browser. This will allow you to delete your browser cookies or
disable them entirely, however, if you choose to disable all cookies, it will affect your
overall experience with our Platform— it may make parts of our Platform non-functional
or inaccessible to you in such cases. Therefore, we recommend that you enable cookies
for our Platform. The cookies we collect are mainly used to serve advertising banners to
you and in doing so we may use the services of third parties. Such third-party service
providers do not know the name, phone number, address, email address, or any
personal information about you, and no personal information is collected or used in this
process.

  
            </li>  
              <li>
            Web beacons: We use web beacons, images that are part of the web pages, also called
pixel tags or clear gifs, in combination with cookies to understand how you interact with
our Platform and the content therein. When you disable cookies, web beacons are also
likely to become ineffective. You can also add browser extensions to specifically block
web beacons.

  
            </li>  
            <br/>
           
       <p>Some of the information that we require you to provide on first accessing or registering on
the Platform is mandatory and/or essential to the provision of our services to you, and hence if
you do not provide such mandatory information, you may not be able to access some of the
features of the Platform.</p>
<br/>
            <br/>

        </ul>
        <br/>
        <br/>
        <br/>
        <h1>How we use your information</h1>
        <br/>
        <p>. In addition to the uses of information we describe elsewhere in this Privacy Policy, we use
your information to do the following:</p>
<br/>
       

        <ul>
            <li>
            enable your access and use of the Platform;
            </li>
            <li>
            process cash transactions to your Account, and enable Withdrawals;


            </li>    <li>
             If You are not legally competent to individually enter into Indian Rupee transactions through
banking channels in India and/or are accessing the Services from a Barred State, You are
prohibited from participating in Cash Games on Our Services. In the event of such violation,
Your participation in Cash Games will be deemed to be in breach of the Platform Policies and
You will not be entitled to receive any prize that You might win in such Cash Games.

  
            </li>   
             <li>
            “Banking Company” means a banking company as defined under section 5(c) of the
Banking Regulation Act, 1949.
  
            </li>   
             <li>
            facilitate your participation in various types of Games and events on the Platform;
</li>
    <li>
    send you Gaddam Studio service-related emails and communications, including
notifications about changes to our policies or Services;

</li>
    <li>
    market our Services or products or services of our group companies, affiliates and
subsidiaries to You.
</li>
    <li>
    handle support requests, including investigating a transaction or issuing a refund;

</li>

<li>
perform internal operations such as troubleshooting, data analysis, testing, research,
statistical, internal training, and quality management purposes aimed at improving the
Platform, enhancing future game developments, and making advertisements more
relevant. Such analysis is aggregated and does not pertain to any specific User.

</li>
<li>
Verification of users and their accounts;


</li>
<li>
measure and understand the effectiveness of the promotions, and advertisements we
serve to you and others;

</li>
<li>
personalize the Platform for you, including the promotions and advertisements that may
be served to you;

</li>
<li>
provide you with user support; and


</li>
<li>
 enforce our Platform Policies.

</li>
<br/>
<p>Where you use our referral program, we use</p>
<br/>
<li>
our name/email address in the heads of the email/SMS sent to the person referred; and

</li>
<li>
the name, mobile number, and email address of the person referred, to communicate
with them.
</li>
<br/>
<p>Optional use: We may use your personal information for the purpose of testimonials and
other promotions.
</p>
<br/>
  
        
        </ul>
        <br/>
        <br/>

        <br/>
        <h1>How we store your information</h1>
        <br/>
       <p>The information collected by us from users of the Platform is securely stored and processed
on servers located in India.
</p>
     <br/>
       <p> Some of our affiliates and/or Service Providers may store or process information in
jurisdictions other than India.
</p>
<br/>

      
        <br/>
        <br/>

        <br/>
        <h1>How we share your information</h1>
        <br/>
        <p>We may share your information with various third parties who assist us in providing you with
our Services, including the following</p>
<br/>
       

        <ul>
            <li>
            Service providers, such as information technology and payment service providers,
whose services form a part of or are necessary for the provision of our Services;


            </li>
            <li>
            Advertisers and advertising networks that require the information to select and serve
advertisements to you and other users;
            </li>    <li>
            Cloud storage providers to store the information you provide and for disaster recovery
services, as well as for the performance of any contract we enter into with you;
  
            </li>    <li>
            Analytics and search engine providers that assist us in the optimisation and
improvement of the Platform; and
            </li>   
             <li>
             Entities that provide us with marketing assistance;


</li>
     <li>
     Entities that provide us with customer support services.


</li>
<br/>
<p>We may share your information with subsidiaries, affiliates and group companies of
Gaddam Studio that aid us in the provision of our Services and the functioning of the Platform.
We may also share your information with subsidiaries, affiliates and group companies of
Gaddam Studio for our internal business purposes including without limitation data analysis</p>
<br/>
<p>We may share your information with law enforcement agencies, public authorities, or other
organizations if legally required to do so, or if such use is reasonably necessary to:</p>
<br/>
<li>
comply with any legal obligation, process, or request from government or judicial
authorities;

</li>
<li>
enforce our Platform Policies, including investigation of any potential violation thereof;

</li>
<li>
detect, prevent, or otherwise address security, fraud, or technical issues; or

</li>
<li>
protect the rights, property or safety of us, our users, a third party or the public as
required or permitted by law (including exchanging information with other companies and
organizations for the purposes of fraud protection).

</li>
<br/>
<p>The service providers we engage are bound by data processing agreements that require
them to take sufficient technical and organizational measures to safeguard your information.</p>
<br/>
<p>If we sell or otherwise transfer our business, or our assets, in full or part, to another
organization (e.g., in the course of a transaction like a merger, acquisition, bankruptcy,
dissolution, or liquidation), your information may be among the items sold or transferred. The
buyer or transferee would be obligated to honor the commitments made in this Privacy Policy.
</p>
<br/>
    
        </ul>
        <br/>
        <br/>

        <br/>
        <h1>How long do we retain your information?</h1>
        <br/>
       

        <ul>
            <li>
            We retain your information for as long as is necessary to provide you with our Services;
thereafter, we may retain your information to comply with our legal obligations, resolve disputes
and enforce our agreements, and to protect Gaddam Studio’s legal rights, subject to limitations
under applicable law.


            </li>
        </ul>
        <br/>
        <br/>

        <br/>
        <h1>Your rights and preferences</h1>
        <br/>
       <p>Access and Updation: You may access and update the personal information that you have
provided to us by logging into your Account, and using the options available therein, though this
may be restricted in instances where you are not able to verify your identity or there is any
suspicious activity associated with your account.</p>
<br/>
<p>Withdrawal from providing information: You have the right to withdraw or decline your
consent to the collection of any information you provide us at any time by writing to us at
support@rummydunia.com. Please note, this may affect your usage and access to our Platform..</p>
<br/>
<p>Communications Opt-Outs: You can opt-out of marketing or advertising emails by sending
an email to support@rummydunia.com</p>
<br/>
       
     

        <br/>
        <br/>

        <br/>
        <h1>Security and protection of your information</h1>
        <br/>
        <br/>
       

<br/>
<p>We use industry standard and legally mandated safeguards to keep the information
collected by us secure. These measures include periodic reviews of our data collection
practices, storage and processing practices, cyber security measures such as encryption, and
physical security measures to guard against unauthorized access to systems where we store
Users’ personal information. We have a comprehensive information security program and
information security policies that contain managerial, technical, operational and physical security
control measures adequate for the protection of personal information, including sensitive
personal information.
</p>
<br/>
<p>When you register with us, your account is protected by means of a unique password,
which is known to or may be accessed only by you. You are responsible for maintaining the
secrecy of your unique password and Account information, and for controlling access to emails
between you and us, at all times. Therefore, you should not provide your password to anyone
whosoever: breach hereof constitutes a violation of this Privacy Policy and may result in
termination of your Account in certain cases. If you become aware of or reasonably suspect any
breach of security, including compromise of your password, you must notify us immediately.
</p>
<br/>


     
        <br/>
        <br/>

        <br/>
        <h1>Information relating to children
</h1>
        <br/>
        <p>The Platform is not intended or directed at anyone under the age of 18, and we do not
knowingly collect or solicit any information from, or allow the registration on the Platform of,
anyone under the age of 18. Upon learning that we have collected personal information from a
user under the age of 18, we will delete that information as quickly as possible. If you believe
that we might have any information from or about a child under age 18, please contact our
Grievance Officer in the manner provided in Clause 10 below.
</p>
        <br/>
        <br/>
        <br/>
        <h1>Third party platforms and services</h1>
        <br/>
      
       <br/>
       <br/>
       <p>The Platform may contain links to third-party websites or services (“External Resource”). Your
browsing on and interaction with any External Resource, irrespective of whether the link to such
External Resource originated on our Platform, is subject to the terms and privacy policies of
such External Resource. We are not responsible for the functionality, privacy, or security
measures of any other entity and this Privacy Policy will not govern your use and access of such
website or service you are redirected to.</p>
    
        <br/>
        <br/>

        <br/>
        <h1> How to contact us?</h1>
       
       <br/>
       <br/>
       <br/>
       <p>If you have any thoughts or questions about this Privacy Policy or the Platform, or any concerns
or grievances about the processing of your personal information by us, please contact our
Grievance Officer at:</p>
<br/>
<p>Name: Mr Srinivas Reddy<br/>
Email address: grievance.officer@rummydunia.com<br/>
Venkateshwara nilayam,<br/>
Hyderabad, Telangana- 500074 India</p>




        <br/>
        <br/>

        <br/>
        <h1>Changes to this Privacy Policy</h1>
       
       <br/>
       <br/>
       <br/>
       <p>Gaddam Studio may modify or update this Privacy Policy from time to time, and we will notify
you, via email or in-app/website notifications of any such changes. However, you are advised to
review this Privacy Policy periodically, as your continued use of our Services after any
modification to this Privacy Policy will constitute your acceptance of such modification.</p>
       
<br/>
       <br/>
       <br/>
        </div>
    <Footer/>
   </>
  )
}

export default PrivacyPolicy