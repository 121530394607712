import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import play from '../Assets/withdraw.png'
import icon from '../Assets/multiple-withdrawlals-2x.webp'
import icon2 from '../Assets/all-payments-desk-2x3.webp'
function Withdrawal() {
  return (
    <>
        <Navbar/>
        <img className='play' src={play}/>

       
        <div className='ResponsibleGaming'>
        <h3>Rummy Dunia withdrawal policy</h3>
        <br/>
        <div className='ResponsibleGaming-icons'>
            <div className='ResponsibleGaming-icon'>
            <img src={icon}/>
            <p>Instant Withdrawal</p>

            </div>
            <div className='ResponsibleGaming-icon'>
            <img src={icon}/>
            <p>More than 95% withdrawal<br/>
            completed in less than 1 min</p>

            </div>
            <div className='ResponsibleGaming-icon'>
            <img src={icon2}/>
            <p>Use UPI and IMPS for<br/>
            instant withdrawal</p>

            </div>
        </div>
        <br/>
        <br/>
<p>Rummy Dunia offers instant money withdrawal to all its users. Instant withdrawal allows you to withdraw your winnings via IMPS in just 30 seconds if your bank supports IMPS transfers.
Your withdrawal will be processed instantly even on weekends & bank holidays. In case your bank does not support IMPS, your withdrawal will be processed via NEFT.</p>
<br/>
<br/><br/>
<h2>Below are the details of the cash withdrawal policy on Rummy Dunia:</h2>
<br/>
<p>You may withdraw your winnings by an electronic ‘bank’ transfer to your verified bank account, subject to successful KYC verification. Please note that the name on the bank account must match the name on the PAN card.<br/><br/>
The withdrawal request along with your bank details need to be filled through your lobby screen on the Rummy Dunia app.<br/><br/>
You can withdraw your entire balance in the withdraw-able segment at any point in time. withdrawal of deposits is not allowed on Rummy Dunia.<br/><br/>
All the withdrawal requests made on Rummy Dunia are subject to applicable taxes.<br/><br/>
Check your bank account statement for “CASHFREE” or “RAZORPAY” once your withdrawal is processed. Cashfree and Razorpay are our payment partners for withdrawals.<br/><br/>
We process your IMPS payment immediately with our payment partners after the withdrawal request is raised successfully and post that bank processes your payment in accordance with their stipulated process time. However, in some cases, a delay might occur. IMPS transactions fail sometimes if your bank is conducting maintenance or having a downtime at the time the transaction is initiated. If this happens, the withdrawal may go through later or may get reversed. If your withdrawal status shows ‘Submitted to bank’ but you haven’t received the credit, then this is the case. The final status of the transaction is only known after 3 working days. You don’t need to worry – if the transaction is reversed, the funds will be credited back to your Rummy Dunia account automatically.<br/><br/>
Account monitoring is done in cases of suspicious games, multiple accounts, etc. Hence the withdrawal is subjected to clearance. It is important to monitor for responsible play as well as to prevent unauthorized or illegal activity.<br/><br/>
Any free referral reward earned through any promotional offer cannot be withdrawn. It can only be used for playing cash games on Rummy Dunia.<br/><br/>
In case of any fraudulent activity, we reserve the right to accept or decline your withdrawal request.<br/><br/>
Please refer to our Terms of service for more details.</p>
        </div>
        <Footer/>
    </>
  )
}

export default Withdrawal