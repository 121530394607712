import React, { useState , useEffect} from 'react';
import Navbar from '../Components/Navbar'
import bannercards from '../Assets/pools_types-transformed 1.svg'
import game1 from '../Assets/image 7.svg'
import game2 from '../Assets/image 10121.png'
import game3 from '../Assets/image 8121.png'
import game4 from '../Assets/image 91231.png'
import game5 from '../Assets/1554554.png'
import icon from '../Assets/icons (2).svg'
import icon2 from '../Assets/icons (3).svg'
import icon4 from '../Assets/icons (4).svg'
import icon3 from '../Assets/Group 39940.svg'
import tabicon from '../Assets/security-verified-solid-svgrepo-com 2.svg'
import ban1 from '../Assets/group 1 (1).svg'
import ban2 from '../Assets/group 1 (2).svg'
import ban3 from '../Assets/group 1 (3).svg'
import ban4 from '../Assets/group 1 (4).svg'
import ban5 from '../Assets/group 1 (5).svg'
import img from '../Assets/Group 39933.svg'
import iconc from '../Assets/cooperate-svgrepo-com 3.svg'
import payments from '../Assets/image 14.svg'
import iconc2 from '../Assets/payment-method-svgrepo-com 1.svg'
import iconc3 from '../Assets/security-verified-solid-svgrepo-com 3.svg'
import button from '../Assets/Button.svg'
import seen1 from '../Assets/image 15.svg'
import seen2 from '../Assets/image 16.svg'
import seen3 from '../Assets/image 17.svg'
import seen4 from '../Assets/image 18.svg'
import seen5 from '../Assets/image 19.svg'
import store1 from '../Assets/Apple btn.png'
import store2 from '../Assets/Play store btn.png'
import table from '../Assets/table.svg'
import Footer from '../Components/Footer';



function Home() {
 

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  const [isOpen6, setIsOpen6] = useState(false);



  // Functions to toggle the state of each accordion
  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
  };

  const toggleAccordion3 = () => {
    setIsOpen3(!isOpen3);
  };
  const toggleAccordion4 = () => {
    setIsOpen4(!isOpen4);
  };
  const toggleAccordion5 = () => {
    setIsOpen5(!isOpen5);
  };
  const toggleAccordion6 = () => {
    setIsOpen6(!isOpen6);
  };
  return (
    <>
        <Navbar/>
        <div className='banner'>
    
        <p>India’s most trusted rummy destination!</p>
        <br/>
    

        <h1>ONE LOVE ONE GAME</h1>
        <br/>
        <br/>

        <div className='playercount'>
<div className='onn'></div>

<p>245678 Players Online </p>

        </div>
        <br/>
        <br/>

<button>DOWNLOAD THE APP</button>
<br/>


<img className='banner-playingcards' src={bannercards}/>

<br/>
<br/>


        </div>
        <div className='bestone'>
          <h2>Best online <span>rummy game</span> experience</h2>
          <br/>
          <br/>
          <br/>
        
        <div className='rummycards-container'>
  <div className='rummycards'>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game1} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>Popular Rummy Variation</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Points, pool & Deals rummy</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Points, pool & Deals rummy</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Points, pool & Deals rummy</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game2} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>Trusted Gaming Platform</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Nation’s trusted rummy app</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>24x7 monitoring for fraud detection</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>RNG certified games-itech Labs</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Anti-collusion tracing system</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game3} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>3 Crore+Players</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Secured Payment Gateway</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Multi-table rummy games</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Instant withdrawals</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game4} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>Play responsibly</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Strict fair play policy</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Limit player’s cash deposit</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>opponent’s profile authentication</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Player Protection Protocol</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game5} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>24x7 Customer Support</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>24x7 Customer Support</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Support in 5 Languages</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Friendly Support For VIP Players</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Player Protection Protocol</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game1} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>Popular Rummy Variation</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Points, pool & Deals rummy</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Points, pool & Deals rummy</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Points, pool & Deals rummy</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game2} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>Trusted Gaming Platform</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Nation’s trusted rummy app</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>24x7 monitoring for fraud detection</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>RNG certified games-itech Labs</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Anti-collusion tracing system</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game3} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>3 Crore+Players</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Secured Payment Gateway</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Multi-table rummy games</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Instant withdrawals</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game4} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>Play responsibly</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Strict fair play policy</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Limit player’s cash deposit</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>opponent’s profile authentication</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Player Protection Protocol</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game5} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>24x7 Customer Support</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>24x7 Customer Support</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Support in 5 Languages</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Friendly Support For VIP Players</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Player Protection Protocol</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game1} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>Popular Rummy Variation</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Points, pool & Deals rummy</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Points, pool & Deals rummy</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Points, pool & Deals rummy</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game2} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>Trusted Gaming Platform</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Nation’s trusted rummy app</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>24x7 monitoring for fraud detection</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>RNG certified games-itech Labs</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Anti-collusion tracing system</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game3} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>3 Crore+Players</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Secured Payment Gateway</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Multi-table rummy games</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Instant withdrawals</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game4} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>Play responsibly</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Strict fair play policy</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Limit player’s cash deposit</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>opponent’s profile authentication</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Player Protection Protocol</p>
        </div>
      </div>
    </div>
    <div className='rummycard'>
      <img className='icon' src={icon} />
      <img src={game5} />
      <br />
      <br />
      <div className='rummycard-text'>
        <h3>24x7 Customer Support</h3>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>24x7 Customer Support</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Support in 5 Languages</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Friendly Support For VIP Players</p>
        </div>
        <br />
        <div className='dp-text'>
          <div className='point'></div>
          <p>Player Protection Protocol</p>
        </div>
      </div>
    </div>
  
  </div>
</div>

        </div>
        <div className='playrummy'>
        <h3>Play rummy online on <span>Rummy Dunia</span></h3>
        <br/>
        <br/>
        <p>Rummy has rapidly gained popularity among the Indian audience because it's a game that is well-suited for various occasions. Indian culture is all about getting together to celebrate even a small occasion. This is where the game of rummy found its place as a form of entertainment during these events. Rummy is a game that can be played among 2 to 6 players with 1-2 standard decks of 52 cards. In the game, every player is dealt 13 cards, and to win, a player must arrange each card in sequences or sets. A player can only make a valid declaration if they</p>
        <br/>
        {/* <br/>
        
        <button>Read More</button> */}

        </div>
        
     {/* <div className='tab-divs'>
     <div className='tabs'>
        <div className='tab'>
        <div className='tab-point'>
        <img src={tabicon}/>

</div>
        <p>Safe and Secure Gaming</p>

</div>
  <div className='tab'>
        <div className='tab-point'>
        <img src={tabicon}/>

</div>
        <p>Safe and Secure Gaming</p>

</div>
  <div className='tab'>
        <div className='tab-point'>
        <img src={tabicon}/>

</div>
        <p>Safe and Secure Gaming</p>

</div>
  <div className='tab'>
        <div className='tab-point'>
        <img src={tabicon}/>

</div>
        <p>Safe and Secure Gaming</p>

</div>
        </div>
        <br/>
        <br/>

        <div className='tabs'>
        <div className='tab'>
        <div className='tab-point'>
        <img src={tabicon}/>

</div>
        <p>Safe and Secure Gaming</p>

</div>
  <div className='tab'>
        <div className='tab-point'>
        <img src={tabicon}/>

</div>
        <p>Safe and Secure Gaming</p>

</div>
  <div className='tab'>
        <div className='tab-point'>
        <img src={tabicon}/>

</div>
        <p>Safe and Secure Gaming</p>

</div>

        </div>



     </div> */}

     <div className='allgames'>
     <div className='gameuppers'>
     <div className='gameuppers-left'>
     <br/>
     <br/>

     <h1>Rummy game variants on<br/><span> Rummy Dunia.com</span></h1>
     <br/>
     <br/>
     <p>To cater to the diverse preferences of rummy players, Rummy Dunia offers three rummy game variants in both free games and cash games formats. Each online rummy variant has its unique elements to make every game super interesting and rewarding.</p>
     <br/>
     
     <p>To play rummy cash games, the player has to Add Cash to try different variants of the game and collect rewards. Rummy Dunia, as a safe and secure platform, allows its players to make all money transactions smoothly and safely, without any hassle. There are three variants of rummy game available on Rummy Dunia:</p>


</div>
 <div className='gameuppers-right'>
 <div className='gameuppers-row'>

 <div className='gameuppers-card'>
 <div className='gamename'>
 <h4>Pool</h4>

 </div>
<br/>
<br/>

 <h2>Pool Rummy</h2>
 <br/>
 <p>Though the format of this variant is a bit longer than the other variants of rummy, the thrill it offers enhances the overall experience of the game. On Rummy Dunia, this variant can be played among 2 to 6 players. With each deal, the game gets more exciting, as the main objective of the game is not to exceed the score limit (61, 101, 201). There is a fixed Buy-In to participate in the game, and the prize pool is formed accordingly. If 6 players are playing the game and join a Pool rummy with a ₹10 Buy-In, the prize pool of the game would be ₹60. The winner will take the entire prize pool of ₹60.</p>

 </div>
 
 <div className='gameuppers-card'>
 <div className='gamename'>
 <h4>Pool</h4>

 </div>
<br/>
<br/>

 <h2>Pool Rummy</h2>
 <br/>
 <p>Though the format of this variant is a bit longer than the other variants of rummy, the thrill it offers enhances the overall experience of the game. On Rummy Dunia, this variant can be played among 2 to 6 players. With each deal, the game gets more exciting, as the main objective of the game is not to exceed the score limit (61, 101, 201). There is a fixed Buy-In to participate in the game, and the prize pool is formed accordingly. If 6 players are playing the game and join a Pool rummy with a ₹10 Buy-In, the prize pool of the game would be ₹60. The winner will take the entire prize pool of ₹60.</p>

 </div>

 </div>
<br/>
<br/>
 <div className='gameuppers-col'>
 <div className='gameuppers-col-card'>

<div className='gamename2'>
<h2>DEALS</h2>
</div>
<div className='gamename2-text'>
  <h3>Deals Rummy</h3>
  <br/>
  <p>Similar to Points rummy, Deals rummy is a fast-paced variant of rummy available on Rummy Dunia. The concept of collecting chips while playing a fixed number of deals allows players to use their skills and strategy throughout the game. If 6 players are playing the game and join a Deals rummy with a ₹10 Buy-In, the prize pool of the game would be ₹60. The winner will take the entire prize pool of ₹60.</p>
  <br/>
  <p>Each variant of rummy on Rummy Dunia possesses the fun and entertaining elements of online gaming. Accessibility to Free Rummy Games for every player allows them to delve into learning something new and gain more skills every day without spending on the game. Also, the round-the-clock availability of the customer support team enhances the overall gaming experience.</p>
</div>
 </div>

</div>

</div>

     </div>
     <br/>
     <br/>
     <br/>
  
    <div className='flexxss-div'>
   <div className='flec-senter'>
   <div className='flexss'>
    <div className='flexss-div'>
      <div className='flexss-divs'>
      <img src={ban1}/>
      <img src={ban2}/>
      <img src={ban3}/>
      <img src={ban4}/>
      <img src={ban5}/>
      </div>
    </div>
     </div>
   </div>
    </div>
     <br/>
     <br/>
     <br/>
     </div>
     {/* <div className='talk'>
     <br/>
     <br/>
     <h1><span>Champions</span> talk</h1>
     <br/>
     <br/>
     <div className='Champions'>
  <div className='Champions-div'>
  <div className='Champions-divs'>

  <img src={img}/>
      <img src={img}/>
      <img src={img}/>
      <img src={img}/>
  </div>
  </div>

     </div>
     <br/>
     <br/>
     <button>View All</button>

     </div> */}
     <div className='Responsiblegaming'>
     <div className='Responsiblegaming-card'>
     <div className='Responsiblegaming-icon'>
     <img src={iconc}/>

     </div>
     <br/>
     <br/>
     <br/>
     <h2>Responsible gaming</h2>
     <br/>
     <p>Players must be minimum 18 years of age to play real money rummy games. This game may be habit-forming or financially risky. Play responsibly.</p>

</div>
  <div className='Responsiblegaming-card'>
     <div className='Responsiblegaming-icon'>
     <img  src={iconc2}/>

     </div>
     <br/>
     <br/>
     <br/>
     <h2>Secured payments partners</h2>
     <br/>
     <br/>
    <img className='payimg' src={payments}/>
</div>
  <div className='Responsiblegaming-card'>
     <div className='Responsiblegaming-icon'>
     <img src={iconc3}/>

     </div>
     <br/>
     <br/>
     <br/>
     <h2>100% Legal</h2>
     <br/>
     <p>Rummy was declared to be a game of skill by the Hon'ble. Supreme Court of India in 1968 and is 100% legal to play for free or cash in most parts of India.</p>

</div>

     </div>
     <div className='FAQ'>
     <h1>Frequently asked <span>questions</span></h1>
     <br/>
     <br/>
     <div className='questions'>
      <div className='questions-card' onClick={toggleAccordion1}>
        <p>Is the rummydunia app safe to use?</p>
        <img src={button} alt="Toggle Button" />
      </div>
      <div className={`questions-content ${isOpen1 ? 'active' : ''}`}>
        <p>Yes, rummydunia is entirely safe and secure to use, and follows strict fair gameplay policies</p>
        <br/>
        <ul>
          <li>rummydunia is an ISO-certified gaming platform.
</li>
<li>All payment gateways used by rummydunia are PCI DSS (Payment Card Industry Data
Security Standards) compliant. They follow a universal set of rules and regulations that
are set by global entities like Visa, Mastercard, and other international organizations.

</li>
<li>rummydunia has a “No-bot” certification, which clearly states that we do not have any
systems that allow the usage of bots to control, mimic, assist, aid, or override real player
actions.

</li>
<li> rummydunia’s card shuffling system has been RNG certified by iTech Labs (Australia).
RNG uses an algorithm to generate random numbers which ensures that all players get
cards selected randomly from a well-shuffled deck and are dealt with fairly by the system
without any bias.

</li>
        </ul>


      </div>
      <br/>
      <br/>

      <div className='questions-card' onClick={toggleAccordion2}>
        <p>How do I download the rummydunia app? Is it available on Google
Play Store?
</p>
        <img src={button} alt="Toggle Button" />
      </div>
      <div className={`questions-content ${isOpen2 ? 'active' : ''}`}>
        <p>There are two ways to get the rummydunia app on your mobile:</p>
        <br/>
        <ul>
         <p>

         Download it from www.rummydunia.com
         </p>
         <br/>


<li>Visit www.rummydunia.com and download the app by simply clicking on the
“Download Now” button.

</li>
<li>You will need to install the downloaded apk.

</li>
<li>Register on the app with your mobile number to start playing.</li>
        </ul>
        <br/>
        <ul>
         <p>

         Download it from Google Play Store.
         </p>
         <br/>


<li>Open Google Play Store on your mobile and search for “rummydunia cash app”.

</li>
<li>Download the rummydunia cash app.
</li>
<li>Register with your mobile number.</li>
<li>Complete your KYC verification.
</li>
<li>You will need your PAN card and address proof (Aadhaar card, Voter ID, etc.) to
verify your KYC. It only takes 2 minutes.</li>
<li>Once your KYC documents have been verified, you can start playing cash games
on the app.
</li>

        </ul>
      </div>
      <br/>
      <br/>
      
      <div className='questions-card' onClick={toggleAccordion3}>
        <p>Can I play rummy on the rummydunia app without depositing any
money?
</p>
        <img src={button} alt="Toggle Button" />
      </div>
      <div className={`questions-content ${isOpen3 ? 'active' : ''}`}>
        <p>Yes, our practice games are free. You can play as many practice games as you want.</p>
     
      <br/>
        <ul>
     
      


<li>Open the rummydunia app and click on “Practice”, located at the bottom of each game
variant.


</li>
<li>Select the practice game you want to play (Deals, Pool, Points).

</li>
<li>Select the number of players you want to play with (2 or 6) and start the game</li>
        </ul>
        <br/>
        <p>You can also try out our free tournaments.</p>

        <br/>

        <ul>
     
      


<li>Open the rummydunia app and click on “Tournaments”, located at the left corner.



</li>
<li>Click on “Free Tourney”. You will see all the free tournaments available for you to play.


</li>
<li>Select the tournament you want to play and register for it.</li>
<li>Check the timings and wait for it to start.
</li>

        </ul>

      </div>
      <br/>
      <br/>
      
      <div className='questions-card' onClick={toggleAccordion4}>
        <p>How can I add cash to the rummydunia account? And is there any
maximum or minimum limit?
</p>
        <img src={button} alt="Toggle Button" />
      </div>
    
      <div className={`questions-content ${isOpen4 ? 'active' : ''}`}>
     
      <p>To add cash to the rummyDunia account:</p>
      <br/>
        <ul>
     
      


<li>Click on the “Add Cash” button located at the top right corner of the app.


</li>
<li>Enter the amount you want to add.</li>
<li>Select a payment mode through which you want to add cash.


</li>
<li>You can add cash through UPI (GPay, PhonePe, Paytm), credit/debit cards, Paytm
wallet, and net banking.



</li>
<li>The minimum amount you can add is ₹50.


</li>
<li>You need to be a KYC-verified player to add cash. It just takes 2 minutes to verify your
documents.


</li>
<li>You can add anywhere between ₹50 to ₹50,000 if your KYC is complete.
</li>


        </ul>
        <br/>
        <p>You can also try out our free tournaments.</p>

        <br/>

        <ul>
     
      


<li>Open the rummydunia app and click on “Tournaments”, located at the left corner.



</li>
<li>Click on “Free Tourney”. You will see all the free tournaments available for you to play.


</li>
<li>Select the tournament you want to play and register for it.</li>
<li>Check the timings and wait for it to start.
</li>

        </ul>

      </div>

      <br/>
      <br/>

      <div className='questions-card' onClick={toggleAccordion5}>
        <p>Is my data (personal info/bank account details) safe with
rummydunia?
</p>
        <img src={button} alt="Toggle Button" />
      </div>
      <div className={`questions-content ${isOpen5 ? 'active' : ''}`}>
        <p>Yes, your data is completely safe with rummydunia.
</p>
     
      <br/>
    
        <ul>
     
      


<li>At rummydunia, we take technical and organizational measures to keep your personal
information and bank account details secure.


</li>
<li>All information transmission across systems is done through secure channels to prevent
the interception of sensitive data.
</li>
<li>Our app is SSL (Secure Sockets Layer) certified. It keeps all your data safe through
encryption

</li>
<li>As per our corporate policy, we do not share your data with any 3rd party
</li>
        </ul>
       </div>

       <br/>
      <br/>

      <div className='questions-card' onClick={toggleAccordion6}>
        <p>Is my money safe on rummydunia?
</p>
        <img src={button} alt="Toggle Button" />
      </div>
      <div className={`questions-content ${isOpen6 ? 'active' : ''}`}>
        <p>Yes, your money is safe with rummydunia.

</p>
     
      <br/>
    
        <ul>
     
      


<li>The amount you deposit in your rummydunia account is maintained in an escrow
account with a regulated entity.


</li>
<li>An escrow account is maintained by a 3rd party which ensures all your payments are
swift and secure.

</li>
<li>All our payment gateways adhere to PCI DSS (Payment Card Industry Data Security
Standards). They follow a universal set of rules and regulations that are set by global
entities like Visa, Mastercard, etc.
</li>
<li>By verifying your KYC details, we apply additional checks to keep your account safe.
</li>
        </ul>
       </div>






    </div>
   

{/* <br/>
<br/>
<br/> */}
{/* <h4>As seen on</h4>
<br/>
<br/>
<div className='asseen'>
<div className='asseen-div'>
<div className='asseen-divs'>
  <img src={seen1}/>
  <img src={seen2}/>
  <img src={seen3}/>
  <img src={seen4}/>
  <img src={seen5}/>
</div>
</div>


</div> */}


     </div>

     <div className='truted'>
     <div className='truted-left'>
     <h1>Play <span>Rummy Dunia</span> on <br/>nation’s trusted <br/>Rummy App </h1>
     <br/>
     <p>Download Rummy Dunia App</p>
     <br/>
     <div className='stores'>
      <img src={store1}/>
      <img src={store2}/>

     </div>

</div>
  <div className='truted-right'>
<img src={table}/>
</div>

     </div>

     <Footer/>
    </>
  )
}

export default Home