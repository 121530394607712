import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Disclaimer() {
  return (
   <>
    <Navbar/>

    <div className='playrummy'>
        <h3> <span>Disclaimer</span></h3>
        <br/>
        <br/>
        <p>Rummydunia.com provides no warranty or assurance of the accuracy or completeness of the
content available on the Rummy Dunia website. All the content or materials on the website
including services, information, products, text, graphics and links are provided on an "AS IS"
basis without any warranty of any kind.
<br/>
<br/>

Rummydunia.com expressly disclaims all kinds of warranties, expressed or implied, related to
the fitness and merchantability for a particular purpose, non-infringement of third parties,
freedom from computer virus, etc.
<br/>
<br/>
Rummydunia.com does not warrant that the functions and services available on the website will
be error-free and uninterrupted. It also does not warrant that the servers used for the website
are free from computer viruses and other harmful materials. Rummydunia.com does not make
any representations or assurances regarding the completeness, accuracy, adequacy and
reliability of the materials used on the website. Users should use the services and content on
the website at their own risk and Rummydunia.com will not be responsible for any damage to
the users’ computers or anything else and hence will not bear the costs of the repair, servicing
or replacement of the users’ computers/products.
<br/>
<br/>
Rummydunia.com does not endorse any advertisements hosted on the website. Users may
view third party advertisements and purchase the products advertised at their own risk.


</p>
      

        <br/>
        <br/>
        
      

        </div>
    <Footer/>

   </>
  )
}

export default Disclaimer