import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Rules() {
  return (
    <>
        <Navbar/>
        <div className='playrummy'>
        <h3> Rummy <span>Rules</span></h3>
        <br/>
        <br/>
        <p>The rules of rummy differ slightly depending on the specific variation of the game. However, the
fundamental rule of the rummy card game remains consistent, with only slight variations in point
value calculations</p>
      

        <br/>
        <br/>
        
      

        </div>
      
        <div className='rules'>
        <br/>
        <br/>
        <h1>Rules to Play Rummy Game Online</h1>
        <p>The different rules of playing rummy are covered below:</p>
        <ul>
            <li>Basic Objective</li>
            <li>Card Value Rules</li>
            <li>Joker Rules</li>
            <li>Sequence Rules</li>
            <li>Rules for Sets</li>
            <li>Rules to Discard, Drop and Show</li>
            <li>Points Calculation Rules</li>
            <li>FAQs</li>


        </ul>
<br/>
<p>Download these Rummy Rules as PDF: Rummy Rules PDF 2023</p>
<br/>

<h1>Basic Objective of a Rummy Game</h1>
<br/>
<p>In a rummy game, each player is dealt 13 cards, and their objective is to create valid
sequences. According to the rummy rules, sequences can be classified as pure sequences (a
consecutive run of 3 or 4 cards of the same suit), impure sequences (a consecutive run of 3 or 4
cards with the help of a joker), and triples (3 cards of the same rank but different suits). The key
to winning any rummy variant is to have at least one pure sequence in your hand.
</p>
<br/>
<h1>Rummy Card Value Rules</h1>
<br/>
<p>Rummy follows a specific card ranking from low to high, which includes Ace, 2, 3, 4, 5, 6, 7, 8, 9,
10, Jack, Queen, and King. The Royal cards (Jack, Queen, and King) carry a value of 10 points
each. The Ace can be valued as either 1 point or as a face card (representing 11 points) when
forming sets.

</p>
<br/>
<h1>Joker Rules in Rummy</h1>
<br/>
<p>In rummy, a joker refers to either a printed joker card or a randomly selected card at the start of
each game. In games that use two decks, two joker cards are utilized. The joker card in rummy
serves as a versatile replacement for any other card, allowing players to complete an impure
sequence by substituting the joker for a missing card

</p>
<br/>
<h1>Sequence Rules in Rummy
</h1>
<br/>
<p>
There are five types of sequences in rummy:

</p>
<br/>
<ul>
    <li>Pure Sequence</li>
    <li>Impure Sequence</li>
    <li>Triples</li>
    <li>First Life</li>
    <li>Second Life</li>


</ul>
<br/>
<h1>Pure Sequence</h1>
<br/>
<p>
In rummy, a pure sequence is formed by arranging three or more consecutive cards of the same
suit in a run or group. It is a vital element in the game because, without a pure sequence, a
player is unable to declare a win. 9♥ 10♥ J♥ (or) 5♠ 6♠ 7♠ 8♠ is an example of pure sequence.

</p>
<br/>
<h1>Impure Sequence</h1>
<br/>
<p>
An impure sequence refers to a collection of 3 or more consecutive cards of the same suit but
with one of the cards being a Joker. For example,
</p>
<br/>
<ul>
    <li>2♦ 3♦ Q♠ 5♦ (Where Q♠ is a joker)</li>
    <li>10♠ 4♥ Q♠ K♠ (Where 4♥ is a joker)</li>
</ul>
<br/>
<h1>Triples</h1>
<br/>
<p>
Three cards of the same rank but different suits are known as Triples. A♦ A♠A♥ is an example of
a triple.

</p>
<br/>
<h1>First Life</h1>
<br/>
<p>
The pure sequence in a rummy game is called first life.

</p>
<br/>
<h1>Second Life</h1>
<br/>
<p>
The second life in rummy refers to the second pure or impure sequence.
Learn More: Rummy Sequences
</p>
<br/>
<h1>Rules for Sets</h1>
<br/>
<p>
In the game of Rummy, a set is defined as a cluster of 3 or 4 cards that share the same rank but
have different suits. It is important to note that sets can include Joker cards. However, according
to the rules of Rummy, sets are considered valid only after both the pure sequence and impure
sequence have been formed.
Also Check: How to Play Rummy?
</p>
<br/>
<h1>Rules to Discard, Drop and Show in Rummy</h1>
<br/>
<p>The basic rules to discard, drop and show in rummy are as follows:
</p>
<br/>
<ul>
    <li>Rules to Discard</li>
    <li>Rules for Drop</li>
    <li>Rules for Show</li>

</ul>
<br/>
<h1>Rules to Discard Cards in Rummy</h1>
<br/>
<p>During a game of Rummy, every player is initially dealt 13 cards. To begin their turn, a player
must choose a card from either the discard pile or the draw pile. After selecting a card, the
player must also discard one card into the discard pile. The opponent then has the option to pick
up the top card from the discard pile.<br/>
A player needs to discard their final card face down to declare their win. At this point, the player
can call “Rummy” and proceed to show their cards.
</p>
<br/>
<h1>Rules for Drop Option</h1>
<br/>
<p>In Rummy Dunia, players have the option to “drop” when they lack confidence in their chances
of winning and wish to minimize the penalty points. Dropping in Rummy means choosing to exit
the game. If a player decides to drop without drawing a single card from either the discard pile
or the stockpile, they incur a penalty of 20 points. However, if a player drops after their first turn,
they face a higher penalty of 40 points.

</p>
<br/>
<h1>Rules for Show in Rummy</h1>
<br/>
<p>When a player is prepared to declare their cards in Rummy after successfully forming valid sets
and sequences, they can request a Show. To initiate a Show, the player must have a total of 14
cards, with one card set aside or discarded face down for display.
Upon calling for a Show, the player is required to arrange the remaining 13 cards into proper
combinations of sets (cards of the same rank but different suits) and sequences (consecutive
cards of the same suit).
In case of a wrong show, a player will have to bear 80 penalty points

</p>
<br/>
<h1>Rules to Calculate Points in Rummy
</h1>
<br/>
<p>The calculation of points in Rummy depends on the specific variant being played.
The cards are ranked from highest to lowest in points rummy- A (highest), K, Q, J, 10, 9, 8, 7, 6,
5, 4, 3, 2 (lowest).
</p>
<br/>
<ul>
<li>Winner’s score: The player who makes a valid declaration (one pure sequence and a
combination of sets and sequences) gets 0 points.</li>
<li>Formula for calculating the winner’s score: (Sum of points of all opponents) * (Rupee
value of the point) – Platform Fee</li>
</ul>
<br/>
<p>Let us understand how the winner’s score is calculated with an example:
Suppose 3 players are playing a Rs 2/ point game, where player 1 declares rummy
and the other two players lose by 20 and 40 points, respectively. The winner will get 2
*(20+40) = Rs 120.
Meanwhile, players get penalty points for every ungrouped card. For example, if a
player has 6♥ 10♠ and A♣ then the total penalty points will be (6+10+10) = 26.
However, if a player doesn’t have any pure sequence cards in their hand, then all points from all
cards will be added up. In the event that a player has a pure sequence, only the points for the
unarranged cards will be added as a penalty.
</p>
<br/>
<br/>

{/* <div className='reff-table'>
<div className='reff-table-div'>
<div className='reff-table-div-left'>
<p>Friend Does</p>
<p>Add Cash of ₹100</p>
<p>₹500 + GamePlay</p>
<p>₹2500 + GamePlay</p>
<p>₹6500 + GamePlay</p>
<p>₹14000 + GamePlay</p>
<p>₹25000 + GamePlay </p>
<p>₹50000 + GamePlay</p>
<p>Total</p>




</div>
<div className='reff-table-div-right'>
<p>You get<br/>
(Discount Credits)</p>
<p>10</p>
<p>50</p>
<p>100</p>
<p>200</p>
<p>350</p>

<p>500</p>
<p>1290</p>
<p>2500</p>


</div>

</div>

</div>
<br/> */}

<br/>
<h1>FAQs About Rummy Game Rules
</h1>
<br/>
<h4>What are the Basic Rules of Rummy?</h4>

<br/>

<p>In the game of Indian rummy, every player at the table receives a total of 13 cards. The
objective is to organize these cards into specific groups and declare them before anyone else.
During each turn, a player is required to either draw a card from the open or closed deck and
discard one card onto the open deck.

</p>
<br/>
<h4>What is the Format of Rummy?</h4>

<br/>

<p>Indian rummy or 13 card rummy is played between 2-6 players using a standard deck and two
printed jokers.


</p>
<br/>
<h4>What is the Golden Rule of Rummy?</h4>

<br/>

<p>The golden rule of rummy is to always make the pure sequence first because it is one of the
most important steps towards a valid declaration.

</p>
<br/>
<h4>How Many Cards Do You Deal in Rummy?</h4>

<br/>

<p>In almost all rummy variants, 13 cards are dealt to each player. However there are few
expectations like the 21 card rummy where players get 10 cards.

</p>
<br/>
<h4>What Happens in Case of Deals Show?</h4>

<br/>

<p>If a player calls for a show in Deals Rummy, they are willing to reveal their cards on the table. A
player calls for a show whenever they have a valid declaration (one pure sequence and one
pure/ impure sequence or sets). If any player fails to make a valid declaration in Deals Rummy,
80 points get added to their final score.
</p>
<br/>

        </div>


        






        <Footer/>

    </>
  )
}

export default Rules