
import { Link } from 'react-router-dom';
import logo from '../Assets/Frame 3 1.svg'
import React, { useState } from 'react';
function Navbar() {
  const [isDivVisible, setIsDivVisible] = useState(false);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };

  return (
   <>
    <nav>
    <Link to='/'>
        <img src={logo}/>
</Link>
        <ul>
        <Link className='none' to='/'>

            <li>Home</li>
        </Link>
        <Link className='none' to='/about'>


            <li>About us</li>
        </Link>
        <Link className='none' to='/downloadrummyapp'>


            <li>Download The App</li>
        </Link>
        <Link className='none' to='/rules'>


            <li>How to Play</li>
        </Link>
        <Link className='none' to='/contactus'>


            <li>Contact Us</li>
        </Link>
        </ul>
        <i class="bi bi-list" onClick={toggleDivVisibility}></i>
    </nav>
    {isDivVisible && (
        <div className="toggleDiv-ops">
        <div className="toggleDiv">
          {/* Content of the div goes here */}
          <ul>
          <Link className='none' to='/'>

<li>Home</li>
</Link>
<Link className='none' to='/about'>


<li>About us</li>
</Link>
<Link className='none' to='/about'>


<li>Download The App</li>
</Link>
<Link className='none' to='/rules'>


<li>How to Play</li>
</Link>
<Link className='none' to='/contactus'>


<li>Contact Us</li>
</Link>
            <br/>
        <img src={logo}/>

        </ul>
        </div>
        </div>
      )}
   </>
  )
}

export default Navbar