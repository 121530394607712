import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Contact() {
  return (
  <>
    <Navbar/>
    <div className='contactusdiv'>
    <h1>Contact <span>Us</span></h1>
    <p>Want to get in touch or have any concerns? We provide customer support in all major Indian
languages. <br/>Scroll down to know how you can reach us!
</p>
<br/>
<br/>
<br/>
<br/>
    <div className='contactus'>
    <div className='contactus-left'>
    <h2>For any questions related to RummyDunia, check out our
Frequently asked questions.</h2>
<h3>Have any other questions about RummyDunia or want to share any concerns? We’re always
happy to help.</h3>
<br/>
<h2>To get in touch with us, simply share your questions or concerns through
email or the support section in the Rummydunia app.</h2>
<h3>Email us at <span>support@rummydunia.com</span></h3>
<br/>
    <h2>Registered And Corporate Office</h2>
    <h3>GADDAM STUDIO PRIVATE LIMITED,<br/>
H no 3-9-273, Saraswathi Nagar colony,<br/>
L B Nagar, Saroornagar,<br/>
K.V.Rangareddy- 500074, Telangana, India<br/>
CIN No. : <span>U90009TS2024PTC185746</span></h3>

</div>
 <div className='contactus-right'>
 <br/>
 <br/>
 <input placeholder='Name' />
 <input placeholder='Email'/>
 <input placeholder='Mobile No.'/>
 <input placeholder='Message'/>
<button>Send</button>

</div>

    </div>
    </div>
   

    <Footer/>
  </>
  )
}

export default Contact