import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function LegalAndResponsible() {
  return (
    <>
        <Navbar/>
        <div className='legal'>
<h1><span>Legal</span> And <span>Responsible</span></h1>
<p>At RummyDunia we ensure a fair and safe gaming experience. Gaming should always be within
<br/>your means and should not impact you negatively.
</p>
<br/>
<br/>
<h2>Is it legal to play online rummy at <span>RummyDunia.com?</span></h2>
<br/>
<h3>Yes, it is! Online Rummy is completely legal in India.</h3>
<br/>
<h4>It may come as a surprise to you, but playing rummy online at Rummy Dunia is a completely
legal process as stated by the Supreme Court of India. Rummy qualifies as skill-based gaming
for which Supreme Court of India had released the following judgment: “The expression
‘gaming’ in the two Acts has to be interpreted in the light of the law laid-down by this Court in the
two 1957 cases, wherein it has been authoritatively held that a competition which substantially
depends on skill is not gambling. Gaming is the act or practice of gambling on a game of
chance. It is staking on chance where chance is the controlling factor. ‘Gaming’ in the two Acts
would, therefore, mean wagering or betting on games of chance. It would not include games of
skill like horse racing”
</h4>
<br/>
<br/>
<h2>Does playing rummy online be <span>categorized as ‘Gambling’?</span></h2>
<br/>
<h3>No, playing rummy online is not gambling.</h3>
<br/>
<h4>The Constitution of India has a definition for gambling which reads like this: “Gambling includes
any activity or undertaking whose determination is controlled or influenced by chance or
accident or any activity or undertaking which is entered into or undertaken with consciousness
of the risk of winning or losing (eg, prize competitions, a wagering contract)” The Supreme Court
of India has identified rummy as a game of skill and not just chance, hence differentiating it from
gambling. Therefore, the activity to play for cash in a game of skill is protected under the Indian
Constitution – Article 19[1](g). However, in Assam, Odisha, Andhra Pradesh, Meghalaya,
Nagaland, Sikkim and Telangana players are not allowed to play for cash. RummyDunia does
not allow players from these states to play for real money. But, they can still play our practice
games for free. You can Refer to Our Terms of service for more details.
</h4>

        </div>
        <Footer/>
    </>
  )
}

export default LegalAndResponsible