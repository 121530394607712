import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import play from '../Assets/play.png'

function Playresponsibly() {
  return (
    <>
        <Navbar/>
        <img className='play' src={play}/>

       
        <div className='ResponsibleGaming'>
    
        <h2>Responsible Gaming</h2>
        <br/>
        <p>We at Rummy Dunia, value our rummy game players, and hence encourage them to play responsibly. Rummy Dunia.com takes great pride in offering a responsible and enjoyable online rummy gaming experience to its players; by taking protective measures which keep our users away from unpleasant situations that may arise while playing rummy.
        In our efforts to promote responsible online gaming, we have established certain preventive measures and tips:</p>
       <br/>
       <h2>Measures for responsible gaming:</h2>
       <br/>
       <p>
      -  Players must be 18+ years of age to play on Rummy Dunia.<br/><br/>
- Flexible features to limit players’ Net Add Cash Limit. They can set such daily limits for themselves.<br/><br/>
- Platform recommended daily Net Add Cash Limits suggested for certain players based on their gameplay and behaviour.<br/><br/>
- Platform enforced daily Net Add Cash Limits set for certain players as a system-defined deposit limit which the self set Net Add Cash Limit of players/ users cannot exceed. This is based on their gameplay pattern and behaviour, to facilitate well-being of users and avoid irresponsible gaming behaviour.<br/><br/>
- Users can set a predefined limit for non-stop time spent on the app, to discourage users/ players from playing for long hours.<br/><br/>
- We give complete control of the account to the player and we allow players to request to pause/ deactivate or delete their account any time they want.<br/><br/>
- 3P (Player Protection Protocol) - 24/7 monitoring of online rummy games for the detection of any fraudulent behavior.<br/><br/>
- Anti-collusion tracking system is active during each online rummy game.<br/><br/>
- We use advanced encryption techniques to store and protect the details of our players.<br/><br/>
- RNG certification from iTech Labs: We use an algorithm to generate random numbers which ensure that all players get cards selected randomly from a well-shuffled deck and are dealt with fairly by the system without any bias.<br/><br/>
- No-bot certification: Specifically evaluated to confirm that we do not have any loophole in our systems which allow the usage of bots to control, mimic, assist, aid or override real player actions.<br/><br/>
- Players can check the profile of each competitor they are playing with to ensure they are real players.<br/><br/>
       </p>
       <br/>
       <h2>Tips for responsible gaming:</h2>
       <br/>
       <p>Be productive in your daily routine to avoid any chances of getting obsessed with online rummy.<br/><br/>
Keep in mind that playing rummy is a recreational activity meant for fun and entertainment only.<br/><br/>
Make sure you don’t give unwarranted priority to rummy over your daily activities and duties.<br/><br/>
Be smart and don’t try too hard to make up for losses if you are losing many games in a row; rather take your time and refresh before approaching a game again.</p><br/><br/>

<h2>All India Gaming Federation (AIGF) Member</h2>
<br/>
<p>All India Gaming Federation (AIGF) is a non-profit organisation, the oldest and only apex industry body whose self regulation charter includes all online skill-based games in India. We are the founding and principal members of AIGF. For more details, please refer: https://www.aigf.in/</p>
<br/>
<p>For any query please write to us at support@Rummy Dunia.com</p>

       
        </div>

        <Footer/>
    </>
  )
}

export default Playresponsibly