import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import table from '../Assets/table.svg'
import store1 from '../Assets/Apple btn.png'
import store2 from '../Assets/Play store btn.png'
import img from '../Assets/Screenshot 2024-09-03 164807.png'
import img2 from '../Assets/Group 39933.svg'

function Download() {
  return (
   <>
    <Navbar/>
    <div className='truted'>
     <div className='truted-left'>
     <h1>Play <span>Rummy Dunia</span> on <br/>nation’s trusted <br/>Rummy App </h1>
     <br/>
     <p>Download Rummy Dunia App</p>
     <br/>
     <div className='stores'>
      <img src={store1}/>
      <img src={store2}/>

     </div>

</div>
  <div className='truted-right'>
<img src={table}/>
</div>

     </div>
     <div className='dgame'>
     <div className='dgame-left'>
<img src={img}/>
     </div>
     <div className='dgame-right'>
<h2>Download rummy game app
</h2>
<br/>
<p>Rummy Dunia is a rummy app that comes in handy if a rummy player wants to play rummy and win exclusive rewards at any time and anywhere. Whether you are a beginner or an experienced rummy player, it’s quite convenient for everyone to play rummy on Rummy Dunia. The first step to start playing rummy online would be downloading the rummy app, Rummy Dunia, on your Android or iOS device.</p>
<br/>
<p>Every rummy player can easily download the Rummy Dunia app, depending on the device they want to use to play rummy. For Android and iOS users, the app is available on Google Play Store and the Apple App Store, respectively. Another way to play rummy on Rummy Dunia is by downloading the APK directly from the Rummy Dunia website. From downloading the rummy app to playing rummy to withdrawing winnings, every step is safe and secure on Rummy Dunia. Once the rummy app is downloaded, it unlocks free rummy games along with free rewards. So, wait no more! Join 3 crore+ players on Rummy Dunia to win big rewards.</p>

     </div>

     </div>
     {/* <div style={{backgroundColor:'black',paddingTop:'50px',paddingBottom:'50px'}} className='Champions'>
     <br/><br/>
  <div className='Champions-div'>
  <div className='Champions-divs'>

  <img src={img2}/>
      <img src={img2}/>
      <img src={img2}/>
      <img src={img2}/>
  </div>
  <br/>
  </div>

  <br/>

  <br/>

     </div> */}
     
    <Footer/>
   </>
  )
}

export default Download