import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import icon from '../Assets/big-prize.webp'
import flagship1 from '../Assets/sat-bumper.webp'
import img2 from '../Assets/One_Rupee_Powerplay---.jpg'
function Tournaments() {
  return (
    <>
        <Navbar/> 
        <div className='tour'>
<h2>Enjoy India’s biggest rummy tournaments</h2>
<br/>
<br/>

<div className='Worldrecord '>
        <div>
            <img src={icon}/>
            <br/>
            <br/>
            <h3>World record tournaments</h3>
            <p>Big prize money</p>
        </div>
        <div>
            <img src={icon}/>
            <br/>
            <br/>
            <h3>World record tournaments</h3>
            <p>Big prize money</p>
        </div>
        <div>
            <img src={icon}/>
            <br/>
            <br/>
            <h3>World record tournaments</h3>
            <p>Big prize money</p>
        </div>
      </div>
        </div>
        <div className='flagship '>
        <h2>Our flagship tournaments</h2>
        <p>Play and win from a big prize pool</p>
        <br/>
     
        <div className='flagship-cards'>
            <div className='flagship-card'>
            <img src={flagship1}/>
            <br/>
            <p>Explore and play in your favourite event and win up to 1 cr+</p>

            </div>
            <div className='flagship-card'>
            <img src={flagship1}/>
            <br/>
            <p>Explore and play in your favourite event and win up to 1 cr+</p>

            </div>
            <div className='flagship-card'>
            <img src={flagship1}/>
            <br/>
            <p>Explore and play in your favourite event and win up to 1 cr+</p>

            </div>
        </div>
        


        </div>
        <div className='Exciting-range'>
        <h2>Exciting and wide range of tournaments</h2>
        <p>Be a champion of them all and win big prizes</p>
        <br/>
        <br/>
        <br/>
        <div className='Exciting-range-cards'>
        <div className='Exciting-range-card'>
        <img src={img2}/>
        <br/>
        <h3>SIT&GO</h3>
        <p>Today 6:00 PM</p>
        <h2>₹1,500</h2>
        <button>Join * 1</button>

        </div>
        <div className='Exciting-range-card'>
        <img src={img2}/>
        <br/>
        <h3>SIT&GO</h3>
        <p>Today 6:00 PM</p>
        <h2>₹1,500</h2>
        <button>Join * 1</button>

        </div>
        <div className='Exciting-range-card'>
        <img src={img2}/>
        <br/>
        <h3>SIT&GO</h3>
        <p>Today 6:00 PM</p>
        <h2>₹1,500</h2>
        <button>Join * 1</button>

        </div>

        </div>

        </div>
        
        <div className='Rummytournaments-text'>
        <h2>Rummy tournaments on Rummy Dunia</h2>
        <br/>
        <p>
        When it comes to tournaments, we’ve created a new world record, twice. Yes, we are currently the Guinness World Records holder for the biggest online rummy tournament. Tournaments on Rummy Dunia are simple and easy to play. We have two types of tournaments on the platform - cash tournaments and free tournaments:
        </p>
        <br/>
        <h3>Cash tournaments</h3>
        <br/>
        <p>
        As the name suggests, cash tournaments require you to pay a certain entry fee to participate in the tournament. Here are a few types of cash rummy tournaments on Rummy Dunia:
        </p>

        </div>
        


      

        <Footer/>
    </>
  )
}

export default Tournaments