import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function TermsOfService() {
  return (
    <>
        <Navbar/>
        <div className='termsdiv playrummy'>
        <h3>Terms of <span>Service</span></h3>
        <br/>
        <br/>
        <p>RummyDunia is an online platform offered by Gaddam Studio Private Limited (hereinafter
referred to as “Gaddam Studio”, “RummyDunia” or “We” or “Us” or “Our”) that enables users
(“You” or “Yourself”) to play the game of rummy online with other users.
</p>
        <br/>
        <p>These terms of service for RummyDunia (“Terms”) along with RummyDunia’s Privacy Policy
(“Privacy Policy”), Fair Play Policy (“Fair Play Policy”), and the cumulative set of Game Rules
(as defined below) (together, “Platform Policies”) govern the relationship between You and
Gaddam Studio. The Platform Policies form a binding legal agreement between You and
RummyDunia, based on which You may visit, access, or use the RummyDunia product, website,
mobile applications, and related mobile applications and services (“Services”)
</p>
 <br/>
        <p>Your use of the Services indicates Your acceptance of the Platform Policies and hence You are
advised to read the Platform Policies carefully before using Our Services. If You do not accept
any part of the Platform Policies or any subsequent modification therein, You must stop
accessing or using Our Services.
</p>
 <br/>
        <p>We reserve the right to periodically review, update, or otherwise modify any part of the Platform
Policies at Our sole and absolute discretion, and accordingly, You are advised to keep Yourself
updated on the latest versions of the Platform Policies. We will provide You with notice of any
material modification to the Platform Policies, and Your continued access and use of the
Services after such notification shall constitute consent to the modified Platform Policies.
In the event where one or more of the terms hereunder are determined to be invalid or unlawful
for any reason, by a competent judicial or quasi-judicial authority in India, the validity or
enforceability of the remaining terms will not be affected and the same will be valid and binding
on You and all other users.

</p>
    <br/>
        </div>
        <div className='conditions'>
        <br/>
        <br/>
        <br/>
        <h1>Definitions</h1>
        <br/>
       

        <ul>
            <li>
            “Account” means the User account maintained with Gaddam Studio, which enables Users
to play Games.
  
            </li>
            <li>
            “Applicable Law(s)” means all applicable statutes, enactments, laws, ordinances, treaties,
conventions, protocols, bye-laws, rules, regulations, guidelines, notifications, notices, and/ or
judgments, decrees, injunctions, writs or orders, instructions, decisions of any court or awards or
other requirements or official directives of any governmental authority, in any jurisdiction, as may
be applicable to the relevant person.

            </li>    <li>
            “Bank Account” means a financial account maintained with a Banking Company or a
financial institution and shall include a wallet maintained with an entity authorized by the
Reserve Bank of India.
  
            </li>    <li>
            “Banking Company” means a banking company as defined under section 5(c) of the
Banking Regulation Act, 1949.
  
            </li>    <li>
            Barred States” means any state in the territory of India where online gaming has been
prohibited under Applicable Laws.

  
            </li>    <li>
            “Buy-in” shall mean the value required to be deployed by a User to play a Cash Game on
the Platform.
  
            </li>    <li>
            “Cash Games” mean Games involving a financial stake, as determined by the participants
of that Game, in accordance with the Game Rules.


  
            </li>    <li>
            “Communication Feature” means the in-game feature that enables users to interact with
each other during a game.
  
            </li>    <li>
            “Deposit Balance Segment” means the segment of the Wallet wherein any money
deposited by a User with RummyDunia to play a Cash Game is credited, after deduction of
applicable goods and services taxes (“GST”) in accordance with Applicable Laws.

  
            </li>    <li>
            . “Discounted Buy-in Value” means the actual value debited from the Wallet of the User as a
Buy-in for a Cash Game, irrespective of the actual Buy-in of that particular Cash Game.

  
            </li>
            <li>
            .“Discount Credits” shall mean non-monetary credits that may be provided by the Platform,
at its sole discretion, as a part of a promotional measure that facilitates a User to play a Cash
Game with Discounted Buy-in Value. Discount Credits cannot be withdrawn, have limited validity
as determined and notified to Users by RummyDunia and cannot be used by a User in any
manner except for its purpose as decided by the Platform.

  
            </li>
            <li>
            “Discount Credit Segment” means the non-monetary segment of the Wallet where
Discount Credits balance shall be displayed to the User as information
  
            </li>
            <li>
            “Game(s)” means the game of rummy offered on RummyDunia and shall include the
various formats, deals, points, pools and tournaments of rummy available on Our Platform.
  
            </li>
            <li>
            “Game Balance Segment” shall mean the segment of the Wallet on the Platform
representing the value available to the User to play Cash Games.
            </li>
            <li>
            “Game Rules”, for each game, means the rules and regulations applicable to the game(s)
which are accessible on the Platform.
            </li>
            <li>
            “Platform” means the RummyDunia website and related mobile/desktop applications on
which Games are made available to users in different formats by Gaddam Studio.
            </li>
            <li>
            “Platform Policies” mean the Terms, Privacy Policy and Fair Play Policy of RummyDunia
and the cumulative set of Game Rules for each game.

            </li>
            <li>
            “Refund” means the reversal of the amounts paid by a User to play a Cash Game
(excluding GST), to such user’s Wallet.
            </li>
            <li>
            “Wallet” means the section of the Account that amongst others enables Users to deposit
and utilize funds on the Platform.
            </li>
            <li>
            “Withdrawable Balance” means the amount in the Winnings Segment
            </li>
            <li>
            “Withdrawal/ Withdraw” means the transfer of all or part of the Withdrawable Balance to the
designated Bank Account of a User, upon specific instructions by that User to RummyDunia to
initiate such transfer.

            </li>
            <li>
            “Withdrawal Request” means a request by a user on the Platform to initiate Withdrawal.

            </li>
            <li>
            “Winnings Segment” means the segment of the Wallet to which the winnings (either in full
or in part) of a User from the Cash Games are credited.


            </li>
        </ul>
        <br/>
        <br/>
        <br/>
        <h1>Who may use Our Services</h1>
        <br/>
       

        <ul>
            <li>
            Our Services are not intended for or offered to any person below the age of 18 (eighteen)
years or persons who are resident in or access the Platform from any of the Barred States. We
have the right to control access to Our Services at all times, in any state in India, and reserve
the right to update the list of Barred States.

            </li>
            <li>
            If You are below the age of 18 (eighteen) years or a resident or located in any of the Barred
States, You may not access and use Our Services. In case You do, You shall be solely
responsible for any legal actions that may ensue from such use of Our Services. We reserve the
right to request proof of age, and geo-location at any stage of Your access and use of Our
Services to verify that persons below the age of 18 (eighteen) years or residing or located in the
Barred States are not using the Service. We may exclude a person from using Our Services
where We suspect that such person using the Service is underage and he/she fails to provide
Us with valid proof of age or is located in a Barred State

            </li>    <li>
             If You are not legally competent to individually enter into Indian Rupee transactions through
banking channels in India and/or are accessing the Services from a Barred State, You are
prohibited from participating in Cash Games on Our Services. In the event of such violation,
Your participation in Cash Games will be deemed to be in breach of the Platform Policies and
You will not be entitled to receive any prize that You might win in such Cash Games.

  
            </li>    <li>
            “Banking Company” means a banking company as defined under section 5(c) of the
Banking Regulation Act, 1949.
  
            </li>    <li>
            We may terminate Your access and use of the Services when We become aware of or, are
of the opinion, at Our sole discretion, that Your use and access of Our Services is in violation of
the Platform Policies.
</li>

  
        
        </ul>
        <br/>
        <br/>

        <br/>
        <h1>How can You access and/or use the Service</h1>
        <br/>
       

        <ul>
            <li>
            RummyDunia allows You to play the game of rummy online with other registered users on
the RummyDunia mobile application, which may be downloaded and installed from our website
www.rummydunia.com, Google Play Store for Android users, and Apple’s App Store for iOS
users or such other app stores.


            </li>
            <li>
            Rummy is a game of skill; therefore, it is legal to play rummy in most states in India

            </li>    <li>
            When You access Our Services and/or create an Account, we may collect, use, share with
third parties, store, or otherwise process Your information, which may include Your personal
information and sensitive personal information, to ensure that the Services may be accessed
and used by You efficiently. The collection, use, and sharing of such information is governed by
the Privacy Policy which forms an integral part of the Platform Policies. You may access the
Privacy Policy here.

  
            </li>    <li>
            You are required to verify Your mobile number at the time of registration on the Platform. All
Your information is kept confidential and processed as per Our Privacy Policy
  
            </li>   
             <li>
            When You register with Us, You agree to receive promotional and service messages from
Us, and our affiliates, through SMS, email, call and push notifications, in relation to tournaments,
offers, and Discount Credits. You may withdraw Your consent to receive such communications
by sending an email to support@rummydunia.com.

</li>

<li>
. Internet connectivity issues at Your end, disruptions due to Your local internet environment,
errors in the setup of software or hardware of Your device, or other factors not directly
attributable to Us, are beyond Our control, and accordingly, You agree that We will not incur any
liability for any halts, breaks, or interruptions in Your usage of Our Services on account of such
issues
</li>
        <li>
        You agree that offers (of various types and nomenclature), and tournaments may have
additional terms and conditions applicable to such offers and tournaments, and You agree that
you will abide by such additional terms and conditions as may be applicable.


</li>
    <li>
    Winnings (whether monetary or non-monetary), Discount Credits (which are strictly
non-monetary), are unique to each player and are non-transferable. In the event You attempt to
transfer any winnings, Discount Credits, it shall be considered a violation of the Terms and any
winnings, Discount Credits, will be forfeited at Our sole discretion.
</li>
    <li>
        You agree that offers (of various types and nomenclature), and tournaments may have
additional terms and conditions applicable to such offers and tournaments, and You agree that
you will abide by such additional terms and conditions as may be applicable.


</li>      
        </ul>
        <br/>
        <br/>

        <br/>
        <h1>User Identity</h1>
        <br/>
       

        <ul>
            <li>
            In order to access the Platform and the Services, You will be required to create an Account
and register yourself on the Platform by providing a valid phone number or such other
information as may be required. You agree to provide true, accurate, current and complete
information at the time of registration and at all other times (as required by Gaddam Studio). You
are prohibited from creating or using multiple Accounts on the Platform. If we detect multiple
accounts for a single user, we may take penal action, including cancellation of all amounts in
relation to Discount Credits on linked accounts, and suspension of the linked accounts.


            </li>
            <li>
            When You create an Account with Us, a default username is generated for You, You may
amend or change your display name which shall be your identity (“Username”) on the Platform.
Your chosen Username is confirmed subject to it being in compliance with these Terms.

            </li>    <li>
            Username must not be indecent, objectionable, offensive, or unlawful. Usernames that are
found to violate the intellectual property of any entity, reveal personal information, or be
suggestive of an advertisement or promotional activity, may be rejected.

  
            </li>    <li>
            You are required to verify Your mobile number at the time of registration on the Platform. All
Your information is kept confidential and processed as per Our Privacy Policy
  
            </li>   
             <li>
             Your Username will remain active on the Platform unless We terminate or suspend Your
access to the Platform for a violation of the Platform Policies in accordance with these Terms, or
Your Account becomes inactive in accordance with Clause 11.1.


</li>

    
        </ul>
        <br/>
        <br/>

        <br/>
        <h1>Game Formats and Rules</h1>
        <br/>
       

        <ul>
            <li>
            RummyDunia provides the game of rummy in various formats and variations, including
Cash Games and non-cash games. We may increase or reduce the number of game formats
and variants offered on the Platform, at Our sole discretion. A User shall have the option to
choose the Game of any Buy-in/ Discounted Buy-in depending on his skill level and knowledge.


            </li>
            <li>
            You agree to abide by the applicable Game Rules when playing games on the Platform.
You are required to ensure that You have read and understood the applicable Game Rules
before playing any game. The Game Rules can be found on the ‘Game Rules/How to Play’
page of Our Services (“Game Rules”) and the same forms a part of the Platform Policies.

            </li>    <li>
            We may, from time to time, make additional games available on the Platform. The Platform
Policies will govern all aspects of such newly introduced games, and the relevant Game Rules
for such new game(s) will be made available to You.

  
            </li>    <li>
            The results and winners of each of the games are determined in accordance with the
applicable Game Rules. By registering and/or participating in any game, You agree to these
determinations, which shall not be open to challenge. We will post lists of winners on the
Platform following each game.
            </li>   
             <li>
             In case anything provided in the Game Rules/FAQ (suggestive or otherwise) as appearing
over the Gaddam Studio Platform are found to be in contradiction to the Terms, these Terms
shall prevail to the extent of such contradiction.


</li>
         <li>
         You may win prizes while playing Games on the Platform. Such prizes are subject to the
specific terms and conditions communicated at the time of issuance of the prize.

</li>

    
        </ul>
        <br/>
        <br/>

        <br/>
        <h1>Restrictions on the use of Our Services</h1>
        <br/>
       <p>You shall not upload, distribute, transmit, publish, share, display, or post any content through Our
Services or through any service or facility, including any Communication Feature, provided by Us which:</p>

        <ul>
            <li>
            s defamatory, obscene, pornographic, paedophilic, invasive of another’s privacy,
including bodily privacy, insulting or harassing on the basis of gender, libellous, racially
or ethnically objectionable, relating or encouraging money laundering or gambling, or
otherwise inconsistent with or contrary to the laws in force;

            </li>
            <li>
            is harmful to a child
            </li>    <li>
            We may, from time to time, make additional games available on the Platform. The Platform
Policies will govern all aspects of such newly introduced games, and the relevant Game Rules
for such new game(s) will be made available to You.

  
            </li>    <li>
            infringes any patent, trademark, copyright or other proprietary rights;
            </li>   
             <li>
             deceives or misleads the addressee about the origin of the message or knowingly and
intentionally communicates any information which is patently false or misleading in
nature but may reasonably be perceived as a fact;

</li>
         <li>
         impersonates another person;

</li>
 <li>
 threatens the unity, integrity, defence, security or sovereignty of India, friendly relations
with foreign states, or public order, or causes incitement to the commission of any
cognisable offence or prevents investigation of any offence or is insulting other nation;
            </li>   
             <li>
             contains a software virus or any other computer code, file or program designed to
interrupt, destroy or limit the functionality of any computer resource;

</li>
         <li>
         is patently false and untrue, and is written or published in any form, with the intent to
mislead or harass a person, entity or agency for financial gain or to cause any injury to
any person;


</li>
  <li>
  is otherwise objectionable or undesirable (whether or not unlawful);

</li>
  <li>
  is aimed at soliciting donations, chips or other forms of help;

</li>
  <li>
  disparages Gaddam Studio or any of its subsidiaries, affiliates, licensors, associates,
partners, sponsors, products, services, or websites, in any manner;


</li>
  <li>
  promotes a competing service or product; or

</li>

<li>
violates any Applicable Law.

</li>
<br/>

<p>The Communication Feature for a user may be immediately suspended without any
warning where We are of the opinion that such user is indulging in any activity prohibited under
Clause</p>
<br/>

<p>above, or elsewhere in the Platform Policies. This may also lead to the suspension
of the Account of such a User. You may report any abusive or malicious behaviour by any other
user to us by following the complaints procedure provided in Clause 16 below.
</p>
<br/>

<p>You shall not host, intercept, emulate, or redirect proprietary communication protocols, used
by Us, if any, regardless of the method used, including protocol emulation, reverse engineering,
or modification of Our Services or any files that are part of it.
</p>
<br/>

<p>You shall not frame Our Services, or impose editorial comments, commercial material or
any information on Our Services, alter or modify any content, or remove, obliterate or obstruct
any proprietary notices or labels.
</p>
<br/>

<p>You shall not use Our Services for commercial purposes, including but not limited to use in
a cyber café as a computer gaming centre, network play over the Internet, or through gaming
networks, or connection to an unauthorized server that copies the gaming experience offered by
Us.</p>
<br/>

<p>You shall not purchase, sell, trade, rent, lease, license, grant a security interest in, or
transfer Your Wallet, content, money, standings, rankings, ratings, or any other attributes
appearing in, originating from, or associated with Our Services.</p>
<br/>

<p>Any form of fraudulent activity, including attempting to use or using any other person’s credit
card(s), debit cards, net-banking usernames, passwords, authorization codes, prepaid cash
cards, mobile phones for adding cash to the Deposit Segment, accessing or attempting to
access the Services through someone else’s Wallet is strictly prohibited. Where We become
aware of or, are of the opinion, at Our sole discretion, that You are indulging in such fraudulent
activity, We may terminate Your Wallet and notify the appropriate authorities, where relevant.
</p>
<br/>

<p>If You are an officer, director, employee, consultant, or agent of Gaddam Studio, or a
relative of such persons, You are not permitted to play either directly or indirectly, any Cash
Games which entitle You to any prize, in the course of Your engagement with Gaddam Studio.
For the purposes of these Terms, ‘relative’ shall have the meaning ascribed to it in Section 2(77)
of the Companies Act, 2013.
</p>

<br/>

<p>You undertake that You will not permit any other person to access or use Your Account and
You will not use any form of external assistance to play the games or indulge in any activity
which violates the Platform Policies.

</p>
<br/>

<p>You are prohibited from utilising Our Services for any restricted or unlawful purpose
including, attempting to launder funds available in Your Wallet or deliberately losing money to a
certain player(s).
</p>
<br/>
<p> You shall not send spam emails to any other user of Gaddam Studio, or any other form of
unsolicited communication for any other purpose.

</p>
    
        </ul>

        <br/>
        <br/>

        <br/>
        <h1>Deposit, Payment, Withdrawal, Refund, and Cancellation</h1>
        <br/>
        <br/>
       
<h4>Deposit</h4>
<br/>


        <ul>
            <li>
            All payments on the Platform will have to be made through your Wallet, by following the
process indicated therein. Any money deposited by You with RummyDunia is credited to the
Deposit Balance Segment (exclusive of GST), amounts available with You to participate in Cash
Games are reflected in the Game Balance Segment, and Your winnings are credited to the
Winnings Segment, automatically unless You choose to transfer part of Your winnings to the
Game Balance Segment, basis ongoing promotional offers (if any) on the Platform.. Your Wallet
also provides a record of all transactions made by You on the Platform.

            </li>
            <li>
            All funds collected from You are held in trust by Gaddam Studio and kept unencumbered
in a designated account. By participating in Cash Games, You hereby authorize Gaddam Studio
to appoint a third party/ Trustee/Escrow Agent to manage funds deposited by You on Your
behalf. Subject to these Terms, amounts collected from You are held in trust and kept
unencumbered in a separate non-interest earning bank account. The said accounts are
operated by a third party appointed by Gaddam Studio. From these bank accounts, the payouts
can be made to (a) the users (towards their withdrawals), (b) [Gaddam Studio ( towards its
margin (i.e., the difference between the buy-in/discounted buy-in amount and the prize pool
amount)) and (c) to governmental authorities (including without limitation towards GST, TDS on
net winnings).


            </li>    <li>
            All transactions You make with Us shall only be in Indian Rupees (INR) and We do not
accept any deposits in any other currency or in the form of virtual digital assets. You are free to
deposit funds in the Deposit Balance Segment for the purpose of participating in Cash Games,
subject to the following conditions

  
            </li>   
            <br/>
            <p>- You can only deposit up to INR 50,000 (Indian Rupees Fifty Thousand only) to the
Deposit Balance Segment in a single transaction;</p>
<br/>
            <p>- The monthly deposit limit set by Us with undertakings, indemnity, waiver, and verification
conditions as We deem appropriate in Our sole discretion. We will notify You of any
changes in the deposit limits on the Platform; and
</p>
<br/>
            <p>- GST at the rates prescribed by the Government will be applicable to the amounts
deposited by you in the Deposit Balance Segment.
</p>
<br/>
            
             <li>
             The deposits made by You in the Deposit Balance Segment are purely for the purpose of
enabling your participation in the Cash Games and do not carry or generate any interest or
return. You shall be allowed to transfer the balance lying in the Deposit Balance Segment to the
Game Balance Segment to participate in a Cash Game.
            </li>   
             <li>
             Gaddam Studio will issue a GST compliant invoice to You which shall be available for You
to download on the Platform against the deemed supply of specified actionable claims by
Gaddam Studio to You when you deposit money to the Deposit Balance Segment.


</li>
         <li>
         You cannot transfer any funds from Your Wallet to the Wallet of another registered user

</li>
     <li>
     You hereby represent and warrant that the payment instrument/mode used for adding
cash to Your Wallet belongs to You.
</li>
     <li>
     Credit cards, debit cards, prepaid cash cards, and Internet banking payments are
processed through third-party payment gateways. Similarly, other payment modes also require
authorization by the entity which processes such payments. You understand and agree that We
are not responsible for delays or denials in processing payments at their end. The processing of
payments will be solely in terms of the applicable policies and procedures of the relevant third
parties, without any responsibility or risk at Our end.

</li>
     <li>
     If there are any issues in connection with adding cash/depositing funds on the Platform,
You may send a complaint to Us by following the complaints procedure provided in Clause 16
below
</li>
     <li>
     You understand and agree that we are not liable in the event of Your credit being
delayed or eventually declined, and You are required to follow up with the providers of the
payment instrument You used to initiate the transaction. Once a payment/transaction is
authorized and confirmed, and the funds are received by Us, the amount is credited to the
Deposit Balance Segment (exclusive of GST) and is available for You to transfer it to the Game
Balance Segment to play Cash Games.
</li>
  <li>
  We have the right to cancel a transaction at any time and at Our sole discretion, and if
the relevant payment is successfully realised in such an event, the transaction will be reversed
and the money credited back (exclusive of GST) to the original source of payment.

</li>

       <br/>
        <br/>
       
<h4>Withdrawal</h4>
<br/>
<li>
You may Withdraw all or any part of the Withdrawable Balance to Your Bank Account,
which will be processed by means of an electronic bank-to-bank transfer or such other mode as
per your stated preferences and in accordance with Applicable Laws.

</li>
<li>
You may also choose to transfer a part of Your Withdrawable Balance to the Game
Balance Segment and avail Discount Credits in accordance with the ongoing promotional offers
(such as Superboost or such other nomenclature as may be determined by Gaddam Studio)
being run on the Platform. The percentage of Your Withdrawable Balance that can be
transferred to the Game Balance Segment shall be determined solely by Gaddam Studio in its
discretion and shall be made available to You in the information panel as an option to avail such
offer.

</li>
<li>
At the time of initiating a Withdrawal Request, You are required to provide Us with the
details of the Bank Account to which the funds are to be credited, and such Bank Account must
belong to You.
</li>
<li>
A Withdrawal Request will be accepted by Us subject to adequate KYC verifications,
alignment with the deposit method, and/or security reviews by Our automated systems and risk
management team. You agree that all Withdrawals You make are governed by the following
conditions:

</li>
<br/>
<p>- You shall be allowed to place a Withdrawal Request only once a day.</p>
<br/>

<p>- You can Withdraw all or any part of Your Withdrawable Balance, subject to the levy of
applicable processing fee (depending on the Withdrawal method), and applicable
withholding withdrawal fee, which shall be a discretionary fee charged by Gaddam
Studio for facilitating Withdrawal on the Platform, inclusive of taxes (“Withdrawal Fee”).
The amount of Withdrawal Fee may vary depending on the mode of Withdrawal or such
other parameters as may be determined by Gaddam Studio and is subject to change
from time to time. The amount of Withdrawal Fee applicable on Your Withdrawal shall be
communicated to You at the time You place the Withdrawal request and Gaddam Studio
will issue a GST-compliant invoice to You which shall be available for You to download
on the Platform from the Wallet section.
</p>
<br/>

<p>- You may Withdraw a minimum of INR 25.00 (Indian Rupees Twenty-Five only), and a
maximum of INR 1,00,000.00 (Indian Rupees Two Lakh only). When Withdrawing funds,
payment will be made to You either by electronic wire transfer, or any other manner in
accordance with Your stated preferences and Applicable Laws. We also reserve the right
to disburse the amount to the financial instrument/mode used to add cash to Your
Deposit Balance Segment. The foregoing limits on withdrawal may be changed or
modified by Us from time to time.</p>
<br/>

<p>- Withdrawal Requests shall, subject to Withdrawal restrictions specified in these Terms
and the Platform, be processed post Your KYC verification. We may ask You for KYC
documents at any stage to verify Your address and identity.
</p>
<br/>

<p>- Discount Credits are non-monetary in nature and are not Withdrawable and are subject
to the terms and conditions applicable to their grant and these Terms.</p>

<br/>
<li>
We will attempt to process Your Withdrawal Request in a timely manner, but there may be
delays due to the time required for the KYC verification, security checks, and other processes
involved in completing the Withdrawal transaction. We shall not be liable to pay any form of
compensation to You for the reason of such delays in processing Withdrawal Requests and
remitting payments
</li>
       <br/>
        <br/>
       
<h4>Refund</h4>
<br/>
<li>
You may request a Refund of any unutilized funds in your Deposit Balance Segment by
contacting support@rummydunia.com, completion of KYC and subject to the following
conditions
</li>
<br/>

<p>- Upon receipt of a Refund request from You, the Refund will be processed within two (2)
weeks from the date of receipt of such Refund request and the requisite Refund amount
shall be credited to the original source used to add such funds, subject to applicable tax
deduction at source.
</p>

<br/>
<p>- You may raise a Refund request no later than one hundred and eighty (180) days of
adding such funds to your Deposit Balance Segment.

</p>

<br/>
<p>- Any GST deducted paid to the Government at time of deposit of the funds shall not be
refundable as per Applicable Laws.

</p>

<br/>
<li>
The refund policy for cancelled Cash Games shall be in accordance with the terms and
conditions specified on the Platform at the beginning of the relevant Cash Game.

</li>
<li>
Your Wallet details may be disclosed to the relevant authorities, and requisite amounts
may be withheld from payouts, in order to ensure compliance with Applicable Law and lawful
requests from the government, regulatory bodies, tax authorities or an order of any court of
competent jurisdiction. It is solely Your responsibility to ensure due remittance of all the
applicable taxes.

</li>

    
        </ul>
        <br/>
        <br/>

        <br/>
        <h1>KYC Verification</h1>
        <br/>
      
       

        <ul>
            <li>
            We require Users to undergo an additional online document verification (“KYC”) in the
following scenarios.


            </li>
            <br/>
        <p>where you place a Withdrawal Request of an amount exceeding the applicable limit as
specified on the Platform;</p>
        <br/>
        <br/>
        <p>where we suspect or have been notified that You have violated any of the Platform
Policies;
</p>
        <br/>
        <br/>
        <p>where you have been identified to be in breach of the applicable add cash thresholds;</p>
        <br/>
        <br/>
        <p>where You have been provided any promotional offer from the Platform or have any
winnings credited to your Wallet;
</p>
        <br/>
        <br/>
        <p>as may be required under Applicable Laws;and
</p>
        <br/>
        <br/>
        <p>as may be determined by the Company in its sole discretion from time to time
</p>
        <br/>



            <li>
            As part of Our KYC process, Our automated system enables a mandatory process for users
to upload documents pertaining to their Permanent Account Number (PAN) & Bank Account.

            </li>    <li>
            The KYC documents/information must be uploaded on Our Platform, and We do not accept
submissions in any other form or manner.

  
            </li>    <li>
            At the time of uploading the document/information on Our Platform, You must ensure the
following:
            </li>  
            <br/>
        <br/>
        <p>documents are valid;
</p>
        <br/>
        <br/>
        <p>the document is clear and legible, and shared in a jpg, jpeg, or pdf format; and
</p>
        <br/>
        <br/>
        <p>documents are not password protected.

</p>
        <br/>





             <li>
             The validation of KYC documents may take up to three (3) working days from the date of
submission of the documents. We reserve the right to validate and/or reject the KYC documents
at Our sole discretion. You will be notified of the status of KYC verification on Your registered
mobile number/email address and on Our Platform.


</li>    
        </ul>
        <br/>
        <br/>

        <br/>
        <h1>Discount and Prize</h1>
        <br/>
       <br/>
       <h4>Discount Credits</h4>
       <br/>
       <br/>
        <ul>
            <li>
            When You add cash to the Deposit Segment, make a Withdrawal Request, participate in
any promotional offer being run on the Platform or when You complete actions, as may be
determined by Us in our discretion, Gaddam Studio may, at its sole discretion, offer Discount
Credits to You which are credited to the Discount Credit Segment, as the case may be. Such
Discount Credits may be dependent on certain conditions, such as Your continued usage of Our
Services or participation in Cash Games, or such other conditions as may be determined by Gaddam Studio. You understand that the Discount Credits shall have a defined expiry period,
which may differ based on the ongoing offers.



            </li>
            <li>
            It is clarified that all Discount Credits offered by Gaddam Studio to a User shall be only
applied to facilitate the User to play Cash Games with Discounted Buy-in. Gaddam Studio has
the right to reduce and vary Discount Credits. Further, such Discount Credits may be subject to
specific conditions, such as usage only in specific types of Games, or restricted usage only after
the expiry of a predetermined time period, etc.

            </li>    <li>
            Details pertaining to each Cash Game transaction, including the applicable Discount
Credits to be utilised for that Cash Game, will be accessible by You on an information panel
made available to You before You initiate a Cash Game.
  
            </li>    <li>
            We may also offer other promotional offerings in the form of tournament tickets.

            </li>   
             <li>
             In the event, Your Account is terminated by You voluntarily, or by Us in accordance with
these Terms, all Discount Credits issued/alloted to You shall be cancelled and You shall have no
further right or interest in such Discount Credits.

</li>

<li>
Any violation of the Platform Policies at any stage may result in disqualification from
receiving the Discount Credits or other promotional winnings, if any.

</li>
        <li>
        The Discount Credits allotted to You are not redeemable for any other prize, nor can they
be Withdrawn directly, or transferred to another user.

</li>
    <li>
    Gaddam Studio may at its sole discretion, make available to you Games wherein you may
utilise the Discount Credits allotted to You to participate in such Games. The winnings of such
Games shall also be in the form of Discount Credits and shall be credited to Discount Credits
Segment.

</li>
   
        </ul>
        <br/>
        <br/>

        <br/>
        <h1>Taxes</h1>
       
       <br/>
       <br/>
        <ul>
            <li>
            TDS at the rate prescribed under Applicable Laws shall be levied on all the Net Winnings
(i.e., the difference between total Withdrawals in a financial year and total Deposits in the same
financial year. Financial year is counted from 1 April to 31st March of a particular year). TDS at
the rate of thirty (30) per cent shall be levied on Net Winnings at the time of Withdrawal and at
the end of a Financial Year on the remaining balance of your Net Winnings. The TDS policy,
limits and rates are subject to change as per the Applicable Laws and Gaddam Studio reserves
the right to modify/amend the same as per Applicable Laws. Gaddam Studio’s obligation in this
regard is limited to deducting TDS as required by Applicable Law and providing You with an
appropriate tax deduction certificate; provided valid PAN is made available which should belong
to you. We reserve the right to verify Your PAN from time to time and to cancel any prize should
Your PAN be found inconsistent in Our KYC verification process.


            </li>
            <li>
            You must ensure that the PAN provided by you is linked with Your Aadhaar, valid and
operative in accordance with applicable Income Tax laws. Gaddam Studio shall not be liable to
provide any TDS certificates or TDS credits for Users whose PAN has become inoperative as
per the circular and instructions issued by the Income Tax authorities from time to time. We shall
have no liability or responsibility in connection with Your personal taxation matters

            </li>    
           
   
        </ul>
        <br/>
        <br/>

        <br/>
        <h1>Termination, Suspension, and Opt-out</h1>
       
       <br/>
       <br/>
       <ul>
            <li>
            If You do not log in to Your Account at least once in a twelve (12) month period, Your
Account shall be considered inactive and a maintenance fee of INR 250 per year may be
chargeable against any funds remaining therein (“Inactive Account Fee”) by Gaddam Studio at
its sole discretion.



            </li>
            <li>
            We reserve the right to suspend or terminate Your Account if We find that You are violating
any of our Platform Policies, or breaching/attempting to breach the security of Our Services in
any manner, Your Wallet may be terminated immediately and any Discount Credits, promotional
winnings, or funds therein may be forfeited.


            </li>   
             <li>
             In the event Your Account is terminated by Us in accordance with Clause 11.2, You will:
  
            </li>   
<br/>

            <p>- not be permitted to use Our Services, thereafter, from the mobile number, PAN, and
Bank Account linked to the terminated account; and</p>

<br/>
<p>- be permitted to Withdraw the Withdrawable Balance, subject to applicable restrictions in
the Platform Policies.
</p>

            
            
            
             <li>
             Further, if We find that a user is breaching/attempting to breach Our security or privacy
protocols, We may, following an internal investigation, decide to undertake a range of remedial
actions, depending upon the severity of the detected breach, which may include the following:

            </li> 
            <br/>

            <p>- stop access and usage of Our Services, indefinitely, by such user</p>

<br/>
<p>- permanently terminate such user’s Wallet;
</p>

<br/>

            <p>- restrict Withdrawals for such user;</p>

<br/>
<p>- demand compensation for the losses to Our Services that occurred because of the
breach and prosecute a user for any violation of Applicable Law and the Platform
Policies if need be; and
</p>
<br/>
<p>-  prohibit the user from registering with Us in the future.</p>
<br/>


             <li>
             In the event, We believe or suspect that You have been engaged in Fair Play Violation:

</li>
<br/>
<p>- we will notify You such breach/attempt to breach; and
</p>
<br/>
<p>-  We shall have the right to investigate and monitor Your Wallet and Your gameplay for
such period of time as in Our reasonable opinion may be needed to investigate such
breach which shall extend up to a period of 180 (one hundred and eighty) days from the
date of notification of such breach. The balance funds of both users guilty of Fair Play
Violations will be frozen for a period of such 180 (one hundred and eighty) days. Such
investigation will cover all the games that the concerned User has played since the time
of registering on the Platform.
</p>
<br/>


<li>
In the event, We, upon investigation are of the view that Your Wallet is or has been
engaged in Fair Play Violations, then to the extent of the amount which is involved in such Fair
Play Violations, We may

</li>

<br/>
<p>- forfeit the funds in Your Wallet
</p>
<br/>
<p>-  repatriate the amount to the User or individuals who have been affected by the Fair Play
Violation; and
</p>
<br/>
<br/>
<p>- charge a Fair Play violation compensation of an amount up to an amount of INR 10,000
per instance of such Fair Play Violation. You agree that such compensation is
reasonable and justifiable keeping in mind the manpower, technology, costs, loss of
reputation that We as a Platform may suffer due to Your violation of the Fair Play Policy.
The fair play violation fee may be debited from the funds in Your Wallet or in the event
the balance in Your Wallet is not sufficient to cover the fee, we may raise a claim for
such fee against You in accordance with applicable laws.
</p>
<br/>





        <li>
        For the purpose of these Terms, “Fair Play Violation” means any activity of a User that
violates the Platform Policies , and includes without limitation:
</li>
<br/>
<p>- activity that uses unfair means to manipulate the outcome of a game on the Platform,
irrespective of whether this in favour of the user adopting such means;

</p>
<br/>
<p>-  activity that is fraudulent, illegal, or unfair activity, as determined by Gaddam Studio in its
sole discretion;

</p>
<br/>
<br/>
<p>- activity that is intended solely to transfer funds from one person to another person, or
between distinct sources, thereby amounting to money transferring;
</p>
<br/>
<br/>
<p>- When any User intentionally loses a hand in order to deliberately transfer his chips to
another User (“Chip-Dumping”).

</p>
<br/>
<br/>
<p>- External Player Assistance Programs (“EPA Programs”) or Heads Up Displays (“HUDs”)
which are designed to provide an unfair advantage to any Users. Gaddam Studio
defines “External” to mean computer software (other than the Platform), and
non-software-based databases or profiles (e.g., subscription services).
</p>
<br/>
<br/>
<p>- activity that involves collusion between two or more players to achieve a result during a
game, thereby amounting to syndicate playing; and/or

</p>
<br/>
<br/>
<p>- such other activity as may be determined by Gaddam Studio, from time to time.</p>
<br/>

    <li>
    If We have been instructed or made aware of a cyber fraud or any other fraudulent activity
related to a User’s Account by a governmental, police or any law enforcement authority or
agency, then We reserve the right to (a) suspend Your Account; and (b) transfer any funds lying
in Your Wallet to such Bank Account as may be instructed by the governmental, police or any
law enforcement authority or agency. We also reserve the right to block any and all Withdrawals
from Your Wallet upon instructions from the government, police or any law enforcement
authority or agency.


</li>
    <li>
    We may attempt to validate Your Account via call or email. In the event that We are not
able to get in touch with You on the first occasion, We will make subsequent attempts to
establish contact with You. In cases where the phone number and email address provided by
You is incorrect, We bear no responsibility for Your access and use of the Services being
interrupted. In case We are unable to reach You or if the validation is unsuccessful, We reserve
the right to disallow You from logging onto Our Services or reducing Your play limits and/or add
cash limits till such time We are able to satisfactorily validate Your Account. In such an event,
We will notify You via email of the next steps regarding Your Account validation. We may also
ask You for proof of identification and proof of address from time to time. Where Your access to
Your Account is limited or access to Our Services is restricted as a result of unsuccessful
Account validation, We may take seven (7) working days to reinstate complete access to Your
Account and Our Services upon successful validation. In the event that We have made several
unsuccessful attempts to reach out to You, We reserve the right to terminate Your Account and
refund Withdrawable Balance, if any. We will not be liable for any claim, damage or loss
accruing to You from such termination or restriction.



</li>
<li>
You are free to discontinue using Our Services at any time by sending Us an email
indicating such intention at support@rummydunia (“Discontinuation Request”). If at such time,
there is a positive Withdrawable Balance and/or funds in Your Deposit Segment, We will
disburse the same to You by electronic transfer in accordance with these Terms. In case of a
levy of such fee, Gaddam Studio will issue a GST-compliant invoice to You which shall be
available for You to download on the Platform from the Wallet section.
</li>
   
        </ul>
        <br/>
        <br/>
        <br/>
        <h1>Your Representations and Warranties</h1>
        <br/>
       

        <ul>
            <li>
            You represent that

            </li>
            <br/>
<p>
- You are eligible and competent to enter into transactions with other users and Gaddam
Studio
</p>
            <br/>
            <br/>
<p>
- Any information You provide to Us at any time during the access and use of Our
Services, whether at the stage of registration or any time thereafter is correct, complete,
up-to-date, and accurate. We shall not be liable against any claim relating to the
accuracy, completeness, and correctness of any information You provide Us. We may, at
any time and at Our sole discretion, require You to verify the correctness, completeness,
and accuracy of such information, and to that extent require You to produce additional
documentary proof. Where You fail to provide Us with valid documentary proof, We
reserve the right to limit Your access and use of Our Services.

</p>
            <br/>
            <br/>
<p>
- You will protect the information You provide Us. You must never share Your Account
login information with anyone, including any other user of Gaddam Studio or any
representative of Gaddam Studio. You specifically understand and agree that We will not
incur any liability for information provided by You to anyone which may result in Your
Account being exposed or misused by any other person.
</p>
            <br/>
            
<p>
- You have the required legal rights in relation to any content that You may transmit,
upload, or otherwise make available on the Platform, and such action of Yours shall not
violate the intellectual property rights of a third party.

</p>
            <br/>

            <p>
- You shall use Your Account solely for the purpose of playing the Games offered through
Our Services, and not to launder funds or commit any other unlawful or prohibited
activity.


</p>
            <br/>
            <p>
- Prior to adding cash to the Deposit Segment or participating in Cash Games, You shall
be responsible to satisfy Yourself about the legality of playing Cash Games in the
jurisdiction from where You are accessing Cash Games

</p>
            <br/>
            <p>
- You have the experience and the requisite skills required to play Games on the Platform,
and that You are not aware of or suffering with any physical or mental condition that
would impair Your capability to fully participate in such activity.


</p>
            <br/>
            <p>
- You are aware that playing Cash Games may result in financial loss to You. With full
knowledge of the facts and circumstances surrounding this activity, by using the
Platform. You are voluntarily choosing to play Games, and accordingly assume all
responsibility for and risk resulting from Your participation in any Cash Game or other
activity on the Platform, including any financial loss. You understand that Gaddam Studio
shall not be liable for any financial loss that You may sustain as a result of Your use of
the Platform. You agree to indemnify and hold Gaddam Studio, its employees, directors,
officers, and agents harmless with respect to any and all claims and costs associated
with Your use of the Platform.



</p>
            <br/>
            <p>
- You understand and accept that Your participation in a Game does not create any
obligation on Us to give You a prize. Your winning a prize is entirely dependent on the
outcome of the Game, and Your skill as a player vis-à-vis other players in the Game, and
is further subject to the Platform Policies


</p>
            <br/>
            <p>
- You understand that any Fair Play violation by You or non-adherence to these Terms
shall be deemed to be a breach of contract by You.</p>
            <br/>


            <li>
            Gaddam Studio is not responsible for Your inability to access or play any Game or event in
which You may be eligible to participate, irrespective of the cause of such event. This includes
situations where You are unable to log into Your Account or the same may be pending validation
or You may be in suspected or established violation of any of the Platform Policies.
</li>   
       </ul>
       <br/>
        <br/>
        <br/>
        <h1>Content and Advertisements</h1>
        <br/>
       

        <ul>
            <li>
            You are solely responsible for any content that You transmit, upload, or otherwise make
available on Our Services. By publishing any content on Our Platform, You agree to grant Us a
royalty-free, worldwide, non-exclusive, perpetual, and assignable right to use, copy, reproduce,
modify, adapt, publish, edit, translate, create derivative works from, transmit, distribute, and
publicly display Your content, and to use such content in any related marketing materials
produced by Us or Our affiliates. Such content may include, without limitation, Your name,
username, profile picture and other information provided on the Platform.


            </li>
            <li>
            By accessing or using or availing any part of Our Services, including the Communication
Feature, You may be exposed to content posted by other users, over which Gaddam Studio has
no control. If You find any aspect of such content offensive or unlawful, You may bring such
content to Our notice, and We shall take appropriate remedial actions in accordance with
Applicable Law.


            </li>   
             <li>
             We may place certain promoted or sponsored content on the Platform (directly or via links
to other sites or resources) in conjunction with third parties. We make no warranties with respect
to such content and neither do we endorse any information contained therein. We shall not be
liable to You for such promoted or sponsored content, and Your interaction with such content or
third-party resources shall be solely at Your own risk.

  
            </li>    
            <li>
            We may use any content in relation to the games played on the Platform, including
recordings of the gameplay, for Our corporate and marketing purposes, and accordingly, You
acknowledge, agree, and consent to our right in this regard.

  
            </li>   
          
  
        
        </ul>
        <br/>
        <br/>
        <br/>
        <h1>Disclaimer, Indemnity, and Limitation of Liability</h1>
        <br/>
       

        <ul>
            <li>
            RummyDunia is not liable for any claim, loss, injury, damages, expenses, or lost profits or
winnings, incurred by You in connection with Your use of the Services.



            </li>
            <li>
            Notwithstanding anything to the contrary contained in the Terms, You agree that Our
maximum aggregate liability for all Your Claims against Us, in all circumstances, other than for a
valid Withdrawal, is limited to the [Platform margin] paid to Us over the preceding three (3)
months.


            </li>   
             <li>
             You are solely responsible for any consequence resulting from Your use of the Platform,
and You understand that We assume no liability or responsibility for any financial loss that You
may sustain in this regard.


  
            </li>    
            <li>
            You agree to indemnify and hold harmless Gaddam Studio against any claims, actions,
suits, damages, penalties, or awards brought against Us by any entity or individual in
connection with or in respect of Your use of the Services.
  
            </li>   
            <li>
            The Platform is offered by Gaddam Studio on an as-is basis and Gaddam Studio makes
no implied warranties, with respect to the Service. All implied warranties of merchantability and
fitness for a particular purpose or use, are disclaimed. No one is authorized to make any
warranty on Our behalf, and users may not rely on any other statement of warranty. However,
where we receive adequate notice of an error in Our Services, We shall take prompt steps to
rectify the same.

            </li> 
            <li>
            Gaddam Studio expressly disclaims all responsibility and liability for any harm, damages,
or loss resulting from Your participation in, or cancellation of, any game, any activity or
transactions with third parties whom You may have connected to through Our Services, and any
user-generated content, including any violation of intellectual property rights with respect to
such user-generated content
  
            </li> 
          
            <li>
            A failure by Us to insist upon strict performance of any of the provisions herein or to
exercise any option, right or remedy contained herein, shall not be construed as waiver or
relinquishment of such provisions, option, right or remedy. No waiver of any provision hereof
shall be deemed to have been made unless expressed in writing and signed.
  
            </li> 
            <li>
            The latest version of the Platform Policies as published on the Platform, contains the
entire agreement between You and Us regarding the access to and use of Our Services and
supersedes all prior agreements, including prior versions of the Platform Policies.
  
            </li> 
  
        
        </ul>
        <br/>
        <br/>
        <br/>
        <h1>Intellectual Property Rights</h1>
        <br/>
       

        <ul>
            <li>
            Gaddam Studio and/or its affiliates, as the case may be, exclusively owns all rights and
intellectual property of any type throughout the world, including but not limited to patents,
trademarks, domain names, trade names, service marks, copyrights, software, trade secrets,
industrial designs, and know-how in the Platform and Services, and the underlying technology
and documentation therein (“Gaddam Studio Intellectual Property”).
            </li>
            <li>
            Upon validly registering on the Platform, You are granted a limited right to use Our
Services in accordance with the Platform Policies, and You have no right, ownership, title, or
interest in the Gaddam Studio Intellectual Property.
            </li>   
             <li>
             You are solely responsible for any consequence resulting from Your use of the Platform,
and You understand that We assume no liability or responsibility for any financial loss that You
may sustain in this regard.


  
            </li>    
           
        
        </ul>
        <br/>
        <br/>
        <br/>
        <h1>Complaints and Disputes</h1>
        <br/>
       

        <ul>
            <li>
            . If You have any complaint or grievance relating to any aspect of the Services, You may
approach Our customer care and grievance redressal team for the resolution of such issues by
writing to support@rummydunia.com.
            </li>
            <li>
            You agree that all complaints and disputes (including their existence) with Gaddam Studio
are to be kept confidential. We will endeavour to resolve all grievances within a reasonable time
period, as mandated under applicable Law. Any decision taken by Us relating to any complaint
will be binding on the user.

            </li>   
            <br/>
            <h4>The following are the details of Our grievance officer :</h4>
            <br/>
            <p>Name: Mr Srinivas Reddy</p>
            <br/>
            <p>Designation: Grievance Officer</p>
            <br/>
            <p>Email address: grievance.officer@rummydunia.com</p>

             <li>
             You are solely responsible for any consequence resulting from Your use of the Platform,
and You understand that We assume no liability or responsibility for any financial loss that You
may sustain in this regard.


  
            </li>    
           
        
        </ul>
        <br/>
        <br/>
        <br/>
        <h1>Governing Law, Dispute Resolution & Jurisdiction</h1>
        <br/>
       

        <ul>
            <li>
            The Platform Policies are governed by and shall be interpreted in accordance with the
laws of India.
            </li>
            <li>
            Subject to Clause 17.3, any dispute, controversy, or claim against the Us or arising out of
these Platform Policies shall be subject to the exclusive jurisdiction of the civil courts at
Hyderabad, Telangana.
            </li>   
             <li>
             In the event of any legal dispute arising in relation to any aspect of the Platform Policies,
or the use of our Services by You, You must provide written notification to Us of the dispute and
relevant details. We shall attempt to resolve the dispute through discussions with You, and
where such resolution fails to be concluded within thirty (30) days, the dispute may be referred
to arbitration. The arbitration shall be conducted before a tribunal of three (3) arbitrators wherein
both the Company and You shall be entitled to appoint one (1) arbitrator each and the two (2)
arbitrators shall jointly appoint the third arbitrator. The place of arbitration shall be Hyderabad,
Telangana, and the arbitration proceedings shall be conducted, in English, in accordance with
the provisions of the Arbitration and Conciliation Act, 1996. The award arising from such
arbitration proceedings will be final and binding on the parties, and each party will bear its own
costs of arbitration and equally share the fees of the arbitrator unless the arbitral tribunal
decides otherwise.


  
            </li>    
            <li>Notwithstanding anything in Clause 17.3, Gaddam Studio shall be entitled to seek and
obtain from a court of competent jurisdiction, an interim or permanent equitable or injunctive
relief, or any other relief available under Applicable Law, to safeguard its interest, at any time
during the pendency of a dispute with You or any other person, and such pursuit of relief shall
not constitute a waiver of the arbitration process mandated herein.
</li>
           
        
        </ul>
        <br/>
        <br/>
        <br/>
        <h1>Responsible Gaming</h1>
        <br/>
       

        <ul>
            <li>
            Gaddam Studio is concerned about Your health and well-being, and we constantly take
measures to improve the well-being of our users. You are advised to follow Our Responsible
Gaming Policy.
            </li>
            
        </ul>
        
        </div>

        <Footer/>
    </>
  )
}

export default TermsOfService