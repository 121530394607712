import React from 'react'
import logo from '../Assets/Frame 3 2.svg'
import icon1 from '../Assets/Discord Fill.svg'
import icon2 from '../Assets/Facebook Fill.svg'
import icon3 from '../Assets//Linkedin Fill.svg'
import icon4 from '../Assets/Twitch Fill.svg'
import icon5 from '../Assets/Twitter Fill.svg'
import icon6 from '../Assets/Youtube Fill.svg'
import { Link } from 'react-router-dom'
import f1 from '../Assets/fot1.png'
import f2 from '../Assets/fot2.png'



function Footer() {
  return (
   <>
    <div className='footer'>
    <div className='footer-top'>
    <ul>
    Keep in touch
    <br/>
    <br/>




    <Link className='none' to='/downloadrummyapp'>

        <li>Rummy App Download</li>
    </Link>
    <Link className='none' to='/rules'>


        <li>Rummy Rules</li>
    </Link>
    <Link className='none' to='/tournaments'>

        <li>Rummy Tournaments</li>
    </Link>

    <Link className='none' to='/realcashrummy'>


        <li>Cash Rummy Game</li>
    </Link>
    <Link className='none' to='/indianrummy'>


        <li>Indian Rummy</li>
    </Link>

    </ul>
    <ul>
    Find out more
    <br/>
    <br/>
    <Link className='none' to='/Reffraloffer'>


        <li>Refer Your Friend</li>
    </Link>
    <Link className='none' to='/Reffraloffer'>


        <li>Superboost offer</li>
    </Link>
    <Link className='none'>


        <li className='none' to='/Reffraloffer'>Rummy Offers</li>
    </Link>
    <Link className='none'>


        <li >Champions Talk</li>
    </Link>
    <Link className='none' to='/playresponsibly'>


        <li>Play Responsible</li>
    </Link>

    </ul>
    <ul>
    Our  Company
    <br/>
    <br/>
    <Link className='none' to='/about'>


        <li>About us</li>
    </Link>
    <Link className='none' to='/contactus'>


        <li>Help & Support</li>
    </Link>
 
    <Link className='none' to='/legal'>


        <li>Legality</li>
    </Link>
    <Link className='none' to='/terms'>


        <li>Terms Of Service</li>
    </Link>
    <Link className='none' to='/withdrawal'>


        <li>Withdrawal</li>
    </Link>

    </ul>
    <ul>
    Links You Need 
    <br/>
    <br/>
    <Link className='none' to='/TDSPolicy'>


<li>TDS Policy</li>
</Link>
    <Link className='none' to='/terms'>


        <li>Terms & Conditions</li>
    </Link>
    <Link className='none' to='/privacy'>


        <li>Privacy Policy</li>
    </Link>
    <Link className='none'>


        <li>Press</li>
    </Link>
 
    

    </ul>

    </div>
    <br/>
    <div className='footer-payment'>
    <div className='footer-payment-left'>
    <p>Security and Certification</p>
    <img src={f1}/>

</div>
 <div className='footer-payment-right'>
{/*  <p>Secured payments by</p>
 <img src={f2}/> */}

</div>
    </div>
    <br/>
    <div className='footer-add'>
    <h2>GADDAM STUDIO PRIVATE LIMITED</h2>
    <p>H no 3-9-273, Saraswathi Nagar colony,
    L B Nagar, Saroornagar,</p>
    <p>K.V.Rangareddy- 500074, Telangana, India</p>
    <p>CIN No. : U90009TS2024PTC185746</p>

    <br/>
    <div  class="marquee">
    <p>Players from Meghalaya, Andhra Pradesh, Telangana, Assam, Odisha, Nagaland and Sikkim are not allowed to play online cash rummy. Know More</p>
    </div>
    <br/>
    <p>Nation's Trusted Rummy App Disclaimer: As per a recent study conducted by Unomer (February, 2023) which surveyed 2,000 rummy players across India, aged 21–50, who play rummy on their smartphones.</p>
    </div>

    
    <div className='footer-down'>
    <img src={logo}/>
    <ul>
        <li>
        <Link>

    <img src={icon1}/>
        </Link>
        <Link to='https://www.facebook.com/RummyDunia/'>

    <img src={icon2}/>
        </Link>
    <Link to='https://www.instagram.com/RummyDunia/'>


    <img src={icon3}/>
    </Link>
    <Link>


    <img src={icon4}/>
    </Link>
    <Link to='https://twitter.com/x/migrate?tok=eyJlIjoiL1J1bW15RHVuaWEiLCJ0IjoxNzE2ODc4MjQ4fYX4rOdhkHeVVBY2RmwI9Ow%3D'>

    <img src={icon5}/>
    </Link>

    <Link to='https://www.youtube.com/@rummydunia'>


    <img src={icon6}/>
    </Link>


        </li>
    </ul>

    </div>


    </div>
   </>
  )
}

export default Footer
