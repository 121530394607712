import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Reffral() {
  return (
    <>
        <Navbar/>
        <div className='refferal'>
        <h1><span>Rummy Dunia</span> Referral Offers</h1>
        <br/>
        <h3>Get up to 2,500 Discount Credits for every successful referral
</h3>
        <br/>
        <br/>
<div className='reff-table'>
<div className='reff-table-div'>
<div className='reff-table-div-left'>
<p>Friend Does</p>
<p>Add Cash of ₹100</p>
<p>₹500 + GamePlay</p>
<p>₹2500 + GamePlay</p>
<p>₹6500 + GamePlay</p>
<p>₹14000 + GamePlay</p>
<p>₹25000 + GamePlay </p>
<p>₹50000 + GamePlay</p>
<p>Total</p>




</div>
<div className='reff-table-div-right'>
<p>You get<br/>
(Discount Credits)</p>
<p>10</p>
<p>50</p>
<p>100</p>
<p>200</p>
<p>350</p>

<p>500</p>
<p>1290</p>
<p>2500</p>


</div>

</div>

</div>

        </div>

        <div className='Howitworks'>
        <br/>
        <br/>  <br/>
        <br/>
        <h1>How it works?</h1>
        <br/>
        <br/>
        <ul>
            <li>
            Share your unique referral link or code with friends.

            </li>
            <li>
            Get up to 2,500 Discount Credits as referral rewards if your friend completes
all the milestones mentioned above in the table within 30 days of registration on
Rummy Dunia.


            </li>
            <li>
            Terms of service are applicable to all services offered on Rummy Dunia

            </li>

            <li>
            For any query, please contact customer suppor

            </li>
        
        </ul>
        <br/>
        <br/>
<h1>What if Your Friend Doesn’t Know How to Play Rummy</h1>
<br/>
<p>If your friend doesn't know how to play rummy but is willing to learn it then you need not
worry. The game is easy to learn and play.
</p>
<br/>
<p>You may ask your friends to read through the following sections on the RummyTime
website to get accustomed to the game of rummy:
</p>
<br/>
<ul>
    <li>How to Play Rummy</li>
    <li>Pool Rummy</li>
    <li>Deal Rummy</li>
    <li>Points Rummy</li>
    <li>Rummy Tournaments</li>


</ul>
<br/>
<p>It is said that rummy is an easy game to play and it can be quickly learned by a
beginner. Though a lot of practice is required before beginners start playing real cash
rummy games with experienced and pro players.

</p>
<br/>
<p>It is always recommended to play responsibly and beginners should practice on free
games or free tournaments first where registered rummy players are not required to pay
entry fees.

</p>
<br/>
<h1>Terms and Conditions of the Referral Offer</h1>
<br/>
<ul>
    <li>There is no limit on the number of friends referred.</li>
    <li>The referee should either use the referral code or referral link.</li>
    <li>A person joining through a shared rummy APK link will not be considered as a
referral</li>
    <li>Terms and Conditions can be updated at any time during the offer period. Click
here to read more about the detailed terms and conditions.
</li>
    <li>Following the Finance Act, 2023, TDS Policy is applicable for all players on
Rummy Dunia from 1 April 2023</li>
    <li>From 1st Oct 2023 onwards, GST Policy is applicable for withdrawals from the
platform</li>

</ul>


<br/>
        <br/><br/>
        <br/>
        </div>

        <Footer/>
    </>
  )
}

export default Reffral