import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import logo from '../Assets/Frame 3 1.svg'

function Aboutus() {
  return (
    <>
        <Navbar/>
        <div className='playrummy'>
        <h3>About <span>Rummy Dunia</span></h3>
        <br/>
        <br/>
        <p>Rummy Dunia is the flagship product of Gaddam Studio Pvt. Ltd., which was founded in 2023.
Now part of the Flutter Entertainment Group, Gaddam Studio is a pioneer in the gaming industry
and the fastest-growing skill games company in Southeast Asia.
</p>
        <br/>
        <p>Rummy Dunia, one of the largest rummy brands in the world, is India’s Most Trusted Rummy
Site®. Offering exciting rummy variants, Rummy Dunia is designed to thrill and entertain our
community of players and is known for offering the biggest tournaments and prizes in the
industry
</p>
    <br/>
        <p>Rummy Dunia is committed to providing its players with a fully safe and secure gaming
experience. This popular game of skill is legal to play and has been expressly validated by the
Hon’ble Supreme Court of India as a game of skill that can be played for money. We take pride
in abiding by the law and all government regulations and operate as an industry-leading
Responsible Gaming platform with a code of conduct certified by the E-Gaming Federation.
</p>
    <br/>
        <p>
        Rummy Dunia encourages all its players to play rummy responsibly. Our mission is providing
healthy entertainment in a safe and secure gaming environment and we are committed to
minimizing the risks of excessive gaming by creating awareness and employing ultramodern
technology. We take proactive measures to ensure that each and every player on our platform
has a positive, entertaining, and responsible gaming experience.
</p>

        <br/>
        <br/>
        
      

        </div>
       <div className='donloadtheapp'>
       <div className='donloadtheapp-div'>
<div className='donloadtheapp-logo'>
<img src={logo}/>
<h3>Download App Now</h3>
</div>
<button>DOWNLOAD APP</button>
</div> 
       <br/>
       <br/>
       <br/>
       <br/>
       <br/>

       </div> 
   

        <Footer/>
    </>
  )
}

export default Aboutus