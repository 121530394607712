import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Aboutus from "./Pages/Aboutus";
import TermsOfService from "./Pages/TermsOfService";
import Contact from "./Pages/Contact";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Reffral from "./Pages/Reffral";
import Disclaimer from "./Pages/Disclaimer";
import Rules from "./Pages/Rules";
import LegalAndResponsible from "./Pages/LegalAndResponsible";
import TDSPolicy from "./Pages/TDSPolicy";
import Download from "./Pages/Download";
import Tournaments from "./Pages/Tournaments";
import RealCashRummy from "./Pages/RealCashRummy";
import IndianRummy from "./Pages/IndianRummy";
import Reffer from "./Pages/Reffer";
import Playresponsibly from "./Pages/Playresponsibly";
import Withdrawal from "./Pages/Withdrawal";

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/Reffraloffer" element={<Reffral />} />
        <Route path="/Disclaimer" element={<Disclaimer />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/legal" element={<LegalAndResponsible />} />
        <Route path="/TDSPolicy" element={<TDSPolicy />} />
        <Route path="/downloadrummyapp" element={<Download />} />
        <Route path="/tournaments" element={<Tournaments />} />
        <Route path="/realcashrummy" element={<RealCashRummy />} />
        <Route path="/indianrummy" element={<IndianRummy />} />
        <Route path="/reffer" element={<Reffer />} />
        <Route path="/playresponsibly" element={<Playresponsibly />} />
        <Route path="/withdrawal" element={<Withdrawal />} />











        




      </Routes>
    </Router>
  </>
  );
}

export default App;
