import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import store1 from '../Assets/Apple btn.png'
import store2 from '../Assets/Play store btn.png'
import table from '../Assets/table.svg'
import reff from '../Assets/referral-logo.png'
import reff2 from '../Assets/refer-again.png'
function Reffer() {
  return (
    <>
        <Navbar/>
        <div className='truted'>
     <div className='truted-left'>
     <h1>Play <span>Rummy Dunia</span> on <br/>nation’s trusted <br/>Rummy App </h1>
     <br/>
     <p>Download Rummy Dunia App</p>
     <br/>
     <div className='stores'>
      <img src={store1}/>
      <img src={store2}/>

     </div>

</div>
  <div className='truted-right'>
<img src={table}/>
</div>

     </div>
     <div className='refral'>
        <div>
            <img src={reff}/>
            <p>Refer your friends</p>
        </div>
        <div >
            <img src={reff}/>
            <p>Get rewards</p>
        </div>
        <div>
            <img src={reff2}/>
            <p>Refer again</p>
        </div>
     </div>
     <div className='DiscountCredits '>
     <h2>What are Discount Credits?</h2>
     <br/>
     <p>You can use Discount Credits to play cash games at a discounted value, with up to 100% discount on your Buy-In. You can easily collect Discount Credits by adding cash or by using Superboost to move Withdrawal Balance to Game Balance. Also, Discount Credits expire only when you don’t play any cash game for a period of 30 days or more.</p>
<br/>
<br/>
<br/>
<h2>How do they work?</h2>
     <br/>
     <p>When you play cash games, the available Discount Credits get applied instantly to offer you a discounted Buy-In. Every time you join a table to play, you’ll be shown a pop-up with the amount of Discount Credits being used.</p>

     </div>


        <Footer/>
    </>
  )
}

export default Reffer