import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/indian-rummy-breaker-img-1.webp'
import img2 from '../Assets/real-cash-rummy-breaker-img-2.webp'
import img3 from '../Assets/real-cash-rummy-breaker-img-5.webp'

function IndianRummy() {
  return (
    <>
        <Navbar/>
        <div className='RealCashRummy'>
        <h2>What is Indian Rummy?</h2>
        <br/>
        <br/>
        <p>Indian rummy is one of the most celebrated card games in India. Just like the diverse Indian audience, the game of rummy has evolved over time, resulting in multiple variations. Indian Rummy, being widely accepted, has gained significant popularity among households in India.</p>
        <br/>
        <br/>
        <img src={img}/>
        <br/>
        <br/>
       
        <h3>Types of Indian Rummy</h3>
        <br/>

        <p>Interestingly, there are two types of Indian Rummy: 13-card rummy and 21-card rummy. There isn't much difference between the two versions in terms of game rules; however, their popularity varies.</p>  
        <br/>
        <h3>13-card rummy</h3>
        <br/>
        <p>Indian Rummy, also known as Paplu, is played with 13 cards and can be played among 2 to 6 players. The main objective of the rummy game is to arrange the cards to form sets and sequences and make a valid declaration to win the game.
        </p>
        
        <br/>


        <h3>21-card rummy</h3>
        <br/>

        <p>There is another version of Indian Rummy that is played with 21 cards and can be played among 2 or more players. Even though the rules of this version of the game are almost similar to the 13-card version, it’s not very popular among Indian players.</p>  
        <br/>
        <h3>
        Most Popular Variants of Indian Rummy in 13 Card Format Variants of Indian Rummy
        </h3>
        <br/>


<p>The 13-card Indian Rummy has 3 variants that elevate the fun and entertainment of the game.</p>  
<br/>
<h3>
Deals Rummy
</h3>
<br/>

<p>Similar to Points rummy, Deals rummy is a fast-paced variant of rummy available on
RummyDunia. The concept of collecting chips while playing a fixed number of deals allows
players to use their skills and strategy throughout the game. If 6 players are playing the game
and join a Deals rummy with a ₹10 Buy-In, the prize pool of the game would be ₹60. The winner
will take the entire prize pool of ₹60.
Each variant of rummy on RummyDunia possesses the fun and entertaining elements of online
gaming. Accessibility to Free Rummy Games for every player allows them to delve into learning
something new and gain more skills every day without spending on the game. Also, the
round-the-clock availability of the customer support team enhances the overall gaming
experience</p>  
<br/>
<h3>
Pool Rummy

</h3>
<br/>

<p>Though the format of this variant is a bit longer than the other variants of rummy, the thrill it
offers enhances the overall experience of the game. On RummyDunia, this variant can be
played among 2 to 6 players. With each deal, the game gets more exciting, as the main
objective of the game is not to exceed the score limit (61, 101, 201). There is a fixed Buy-In to
participate in the game, and the prize pool is formed accordingly. If 6 players are playing the
game and join a Pool rummy with a ₹10 Buy-In, the prize pool of the game would be ₹60. The
winner will take the entire prize pool of ₹60.
</p>  
<br/>
<h3>
Points Rummy

</h3>
<br/>

<p>As a fast-paced variant of rummy, Points Rummy demands a combination of skill and strategy.
The seamless gameplay of RummyDunia helps players achieve the necessary pace to perform
well in the game. Based on the pre-decided value of each point, the winner receives the total
points lost by other players. If 6 players are playing on a table that has a point value of 1, then
each point is worth ₹1. Let’s assume that the total score of ungrouped cards of the losing
players is 107, then the winning player gets ₹107.

</p>  
<br/>

        </div>
        <Footer/>
    </>
  )
}

export default IndianRummy